import React, { useEffect, useState } from 'react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTimeoutFn } from 'react-use';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import CardColumns from 'react-bootstrap/CardColumns';
import IncidentCard from '../../components/Cards/IncidentCard';
import { useSelector } from 'react-redux';
import { searchIncidentsAsync } from '../../slices/incidents/incidentSlice';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import { Incident } from '../../lib/types';
import ToggleAutoReloadSwitch from '../../components/Buttons/ToggleAutoReloadSwitch';
import DimmedLoader from 'GuardianWidgetCommons/lib/components/DimmedLoader';
import { TABLE_REFRESH_TIME } from '../../lib/constants';

const cardHeaderStyles = {
    backgroundColor: '#5DBEFF',
    color: 'white',
    textAlign: 'center' as const,
    fontWeight: 'bold' as const,
    fontSize: '1.2rem'
};
const cardBodyStyles = {
    textAlign: 'center' as const,
    fontWeight: 'bold' as const,
    fontSize: '1rem'
};

const Incidents: React.FunctionComponent = () => {
    const [onGoingIncidents, setOnGoingIncidents] = useState<Array<Incident>>([]);
    const [completedIncidents, setCompletedIncidents] = useState<Array<Incident>>([]);
    const [currentTab, setCurrentTab] = useState<string>('all');
    const [isCorpForOnGoing, setIsCorpForOnGoing] = useState<boolean>(false);
    const [isCorpForCompleted, setIsCorpForCompleted] = useState<boolean>(false);
    const [showTabs, setShowTabs] = useState<boolean>(false);
    const { incidents, isLoadingIncidents } = useSelector((state: RootState) => state.incidents);
    const { webRole } = useSelector((state: RootState) => state.userPermissions?.userPermissionRoles);
    const dispatch = useAppDispatch();
    const reloadIncidents = (): void => {
        dispatch(searchIncidentsAsync());
    };
    const [isReady, cancel, reset] = useTimeoutFn(reloadIncidents, TABLE_REFRESH_TIME);

    useEffect(() => {
        setShowTabs(webRole === 'Admin' || webRole === 'Business Team');
    }, [webRole]);

    useEffect(() => {
        dispatch(searchIncidentsAsync());
    }, [dispatch]);

    useEffect(() => {
        setOnGoingIncidents(incidents.filter((incident) => incident.isOpen));
        setCompletedIncidents(incidents.filter((incident) => !incident.isOpen));
    }, [incidents]);

    useEffect(() => {
        setIsCorpForOnGoing(onGoingIncidents.some((incident) => incident.isCorporate));
    }, [onGoingIncidents]);

    useEffect(() => {
        setIsCorpForCompleted(completedIncidents.some((incident) => incident.isCorporate));
    }, [completedIncidents]);

    if (isReady()) {
        reset();
    }

    return (
        <Container>
            <DimmedLoader active={isLoadingIncidents}>
                <ToggleAutoReloadSwitch
                    cancel={() => {
                        cancel();
                    }}
                    reset={() => {
                        reset();
                    }}
                />

                {showTabs && (isCorpForOnGoing || isCorpForCompleted) ? (
                    <Tabs
                        id='ongoingIncidentTabs'
                        defaultActiveKey='all'
                        activeKey={currentTab}
                        onSelect={(key) => setCurrentTab(key ? key : 'all')}
                    >
                        <Tab eventKey='all' title={'All'}>
                            <Card className={'my-3'}>
                                <Card.Header style={cardHeaderStyles}>On Going Incidents</Card.Header>
                                {onGoingIncidents.length ? (
                                    <Card.Body style={cardBodyStyles}>
                                        <CardColumns>
                                            {onGoingIncidents.map((onGoingIncident: Incident) => (
                                                <IncidentCard
                                                    key={onGoingIncident.id}
                                                    incident={onGoingIncident}
                                                />
                                            ))}
                                        </CardColumns>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>

                            <Card>
                                <Card.Header style={cardHeaderStyles}>Completed Incidents</Card.Header>
                                {completedIncidents.length ? (
                                    <Card.Body style={cardBodyStyles}>
                                        <CardColumns>
                                            {completedIncidents.map((completedIncident: Incident) => (
                                                <IncidentCard
                                                    key={completedIncident.id}
                                                    incident={completedIncident}
                                                />
                                            ))}
                                        </CardColumns>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>
                        </Tab>
                        <Tab eventKey='ops' title={'Operations'}>
                            <Card className={'my-3'}>
                                <Card.Header style={cardHeaderStyles}>
                                    On Going Operations Incidents
                                </Card.Header>
                                {onGoingIncidents.length ? (
                                    <Card.Body style={cardBodyStyles}>
                                        <CardColumns>
                                            {onGoingIncidents
                                                .filter(
                                                    (onGoingIncident: Incident) =>
                                                        !onGoingIncident.isCorporate
                                                )
                                                .map((onGoingIncident: Incident) => (
                                                    <IncidentCard
                                                        key={onGoingIncident.id}
                                                        incident={onGoingIncident}
                                                    />
                                                ))}
                                        </CardColumns>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>

                            <Card>
                                <Card.Header style={cardHeaderStyles}>
                                    Completed Operations Incidents
                                </Card.Header>
                                {completedIncidents.length ? (
                                    <Card.Body style={cardBodyStyles}>
                                        <CardColumns>
                                            {completedIncidents
                                                .filter(
                                                    (onGoingIncident: Incident) =>
                                                        !onGoingIncident.isCorporate
                                                )
                                                .map((completedIncident: Incident) => (
                                                    <IncidentCard
                                                        key={completedIncident.id}
                                                        incident={completedIncident}
                                                    />
                                                ))}
                                        </CardColumns>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>
                        </Tab>
                        <Tab eventKey='corp' title={'Corporate'}>
                            <Card className={'my-3'}>
                                <Card.Header style={cardHeaderStyles}>
                                    On Going Corporate Incidents
                                </Card.Header>
                                {onGoingIncidents.length ? (
                                    <Card.Body style={cardBodyStyles}>
                                        <CardColumns>
                                            {onGoingIncidents
                                                .filter(
                                                    (onGoingIncident: Incident) => onGoingIncident.isCorporate
                                                )
                                                .map((onGoingIncident: Incident) => (
                                                    <IncidentCard
                                                        key={onGoingIncident.id}
                                                        incident={onGoingIncident}
                                                    />
                                                ))}
                                        </CardColumns>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>

                            <Card>
                                <Card.Header style={cardHeaderStyles}>
                                    Completed Corporate Incidents
                                </Card.Header>
                                {completedIncidents.length ? (
                                    <Card.Body style={cardBodyStyles}>
                                        <CardColumns>
                                            {completedIncidents
                                                .filter(
                                                    (onGoingIncident: Incident) => onGoingIncident.isCorporate
                                                )
                                                .map((completedIncident: Incident) => (
                                                    <IncidentCard
                                                        key={completedIncident.id}
                                                        incident={completedIncident}
                                                    />
                                                ))}
                                        </CardColumns>
                                    </Card.Body>
                                ) : (
                                    <Card.Body style={cardBodyStyles}>None</Card.Body>
                                )}
                            </Card>
                        </Tab>
                    </Tabs>
                ) : (
                    <>
                        <Card className={'my-3'}>
                            <Card.Header style={cardHeaderStyles}>On Going Incidents</Card.Header>
                            {onGoingIncidents.length ? (
                                <Card.Body style={cardBodyStyles}>
                                    <CardColumns>
                                        {onGoingIncidents.map((onGoingIncident: Incident) => (
                                            <IncidentCard
                                                key={onGoingIncident.id}
                                                incident={onGoingIncident}
                                            />
                                        ))}
                                    </CardColumns>
                                </Card.Body>
                            ) : (
                                <Card.Body style={cardBodyStyles}>None</Card.Body>
                            )}
                        </Card>

                        <Card>
                            <Card.Header style={cardHeaderStyles}>Completed Incidents</Card.Header>
                            {completedIncidents.length ? (
                                <Card.Body style={cardBodyStyles}>
                                    <CardColumns>
                                        {completedIncidents.map((completedIncident: Incident) => (
                                            <IncidentCard
                                                key={completedIncident.id}
                                                incident={completedIncident}
                                            />
                                        ))}
                                    </CardColumns>
                                </Card.Body>
                            ) : (
                                <Card.Body style={cardBodyStyles}>None</Card.Body>
                            )}
                        </Card>
                    </>
                )}
            </DimmedLoader>
        </Container>
    );
};

export default Incidents;
