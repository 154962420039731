import React from 'react';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import {
    FaBell,
    FaCrown,
    FaClock,
    FaClipboardCheck,
    FaRunning,
    FaHome,
    FaChalkboardTeacher
} from 'react-icons/fa';
import { RiAlarmWarningFill } from 'react-icons/ri';
import { Incident } from '../../lib/types';
import {
    differenceBetweenTimes,
    getAccountabilityColor,
    getAccountedForDetails
} from '../../lib/helpers/helpers';
import { useHistory } from 'react-router';
import { INCIDENT_TYPES, INCIDENT_NEW_TYPES } from '../../lib/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';

const ICON_SIZE = 25;
const buttonStyles = { backgroundColor: '#5DBEFF', border: 'none', fontWeight: 'bold' as const };
const alignIconStyles = { display: 'flex', alignItems: 'center' };
const cardBodyStyles = { textAlign: 'left' as const };
const cardStyles = { backgroundColor: '#FFFFFF', color: '#707070', fontWeight: 'bold' as const };
const cardHeaderStyles = {
    backgroundColor: '#FFFFFF',
    color: '#707070',
    textAlign: 'center' as const,
    fontWeight: 'bold' as const,
    fontSize: '1.2rem',
    border: 'none'
};

const IncidentCard: ({ incident }: { incident: Incident }) => JSX.Element = ({ incident }) => {
    const history = useHistory();
    const { showIncidentDetails } = useSelector(
        (state: RootState) => state.userPermissions?.userPermissionActions
    );

    let duration;
    if (!incident.isOpen) {
        duration = differenceBetweenTimes(incident.startTime, incident.endTime);
    } else {
        duration = differenceBetweenTimes(incident.startTime);
    }

    const { percentageAccountedFor } = getAccountedForDetails(
        incident.totalBadgeHolders,
        incident.totalVisitors,
        incident.totalBadgeHoldersAccountedFor,
        incident.totalVisitorsAccountedFor
    );
    const accountabilityColor = getAccountabilityColor(parseInt(percentageAccountedFor));

    const viewIncident = (): void => {
        history.push(`/incidentDetails/${incident.id}`);
    };

    const incidentTypeToIconMap = {
        [INCIDENT_NEW_TYPES.REAL_EMERGENCY]: <RiAlarmWarningFill size={ICON_SIZE} className='mr-2' />,
        [INCIDENT_NEW_TYPES.DRILL]: <FaBell size={ICON_SIZE} className='mr-2' />,
        [INCIDENT_NEW_TYPES.DEMO]: <FaChalkboardTeacher size={ICON_SIZE} className='mr-2' />,
        [INCIDENT_TYPES.DRILL]: <FaBell size={ICON_SIZE} className='mr-2' />,
        [INCIDENT_TYPES.SHELTER_IN_PLACE]: <FaHome size={ICON_SIZE} className='mr-2' />,
        [INCIDENT_TYPES.EVACUATION]: <FaRunning size={ICON_SIZE} className='mr-2' />
    };
    const incidentTypeIcon =
        incidentTypeToIconMap[incident.incidentNewType?.name] ||
        incidentTypeToIconMap[incident.incidentType.name];

    let siteLocation = '';
    if (incident.sites && incident.sites.length) {
        const city = incident.sites[0].city;
        const state = incident.sites[0].state;
        const country = incident.sites[0].country;
        if (city && state) {
            siteLocation = `${city}, ${state}`;
        } else if (city && country) {
            siteLocation = `${city}, ${country}`;
        }
        if (incident.sites.length > 1) {
            siteLocation = siteLocation + ' +';
        }
    }

    return (
        <Card style={cardStyles}>
            <Card.Header style={cardHeaderStyles}>
                {incident.sites?.map((site) => (
                    <Row key={site.siteId}>
                        <Col>{`${site.siteName}${site.siteType ? ` - ${site.siteType}` : ''}`}</Col>
                    </Row>
                ))}
                <Row>
                    <Col style={{ color: accountabilityColor }}>{percentageAccountedFor} Accounted For</Col>
                </Row>
                {siteLocation && (
                    <Row>
                        <Col style={{ fontSize: '1.2rem', fontWeight: '0.5rem' }}>{siteLocation}</Col>
                    </Row>
                )}
            </Card.Header>
            <Card.Body style={cardBodyStyles}>
                <Row>
                    <Col style={alignIconStyles}>
                        {incidentTypeIcon}
                        <span>
                            {incident.incidentNewType?.name && incident.incidentSubType?.name
                                ? `${incident.incidentNewType.name} - ${incident.incidentSubType.name}`
                                : incident.incidentType.name}
                        </span>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col style={alignIconStyles}>
                        <FaCrown size={ICON_SIZE} className='mr-2' />
                        <span>{incident.eventOwner}</span>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col style={alignIconStyles}>
                        <FaClock size={ICON_SIZE} className='mr-2' />
                        <span>{duration}</span>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col style={alignIconStyles}>
                        <FaClipboardCheck size={ICON_SIZE} className='mr-2' />
                        <span>
                            {incident.totalBadgeHoldersAccountedFor - incident.totalContractorsAccountedFor}{' '}
                            of {incident.totalBadgeHolders - incident.totalContractors} Associates
                        </span>
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col style={alignIconStyles}>
                        <FaClipboardCheck size={ICON_SIZE} className='mr-2' />
                        {incident.totalVisitors ? (
                            <span>
                                {incident.totalVisitorsAccountedFor} of {incident.totalVisitors} Visitors
                            </span>
                        ) : (
                            <span>0 of 0 Visitors</span>
                        )}
                    </Col>
                </Row>
                <Row className='mt-2'>
                    <Col style={alignIconStyles}>
                        <FaClipboardCheck size={ICON_SIZE} className='mr-2' />
                        {incident.totalContractors ? (
                            <span>
                                {incident.totalContractorsAccountedFor} of {incident.totalContractors}{' '}
                                Contractors
                            </span>
                        ) : (
                            <span>0 of 0 Contractors</span>
                        )}
                    </Col>
                </Row>
            </Card.Body>
            {showIncidentDetails && (
                <Card.Footer style={cardStyles}>
                    <Button size='sm' style={buttonStyles} className='px-5' onClick={() => viewIncident()}>
                        View Incident
                    </Button>
                </Card.Footer>
            )}
        </Card>
    );
};

export default IncidentCard;
