import React, { Fragment, useEffect, useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import { AvailableRoutes } from '../AppRoutes';
import { useHistory } from 'react-router';
import { COUNTDOWN_LENGTH } from '../lib/constants';

const NoPermissionsViews: React.FunctionComponent = () => {
    const history = useHistory();
    const [countdown, setCountdown] = useState(COUNTDOWN_LENGTH);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCountdown(countdown - 1);
            if (countdown <= 0) {
                viewIncidents();
            }
        }, 1200);
        return () => clearTimeout(timer);
    });

    const viewProfile = (): void => {
        history.push(AvailableRoutes.profile);
    };
    const viewIncidents = (): void => {
        history.push(AvailableRoutes.incidents);
    };

    return (
        <Fragment>
            <Alert variant='warning'>
                <Alert.Heading>You do not have permissions to view this page.</Alert.Heading>
                <p style={{ fontWeight: 'bold', marginTop: '1rem' }}>
                    View your Rally Point permissions{' '}
                    <Alert.Link
                        style={{ textDecorationLine: 'underline', color: 'revert' }}
                        onClick={viewProfile}
                    >
                        here.
                    </Alert.Link>
                </p>
                <p>Redirecting to the incidents page in {countdown}...</p>
            </Alert>
        </Fragment>
    );
};

export default NoPermissionsViews;
