import React, { Dispatch, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import '../../css/Modal.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import Swal from 'sweetalert2';
import {
    CONFIRM_ADD_RESOLVER_GROUP_KEY_MESSAGE,
    RESOLVER_GROUP_KEY_EXISTS_MESSAGES
} from '../../lib/constants';
import { addFacManKeyAsync } from '../../slices/admin/facManKeysSlice';

type Props = {
    isVisible: Dispatch<boolean>;
};

const FacManKeyAdd = ({ isVisible }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const [name, setName] = useState('');
    const { keys } = useSelector((state: RootState) => state.facManKeys);
    const { userCardholderInformation } = useSelector((state: RootState) => state.userProfile);

    const handleSubmit = async (): Promise<void> => {
        const { value: confirmed } = await Swal.fire({
            title: CONFIRM_ADD_RESOLVER_GROUP_KEY_MESSAGE,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#2e7bff'
        });
        if (confirmed) {
            dispatch(addFacManKeyAsync(name, userCardholderInformation.login));
            isVisible(false);
        }
    };
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Modal width='200px' centered show={isVisible} onHide={() => isVisible(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add RESOLVER GROUPS Key</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='formBody'>
                    <Form.Group controlId='inputName'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            as='input'
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            isInvalid={keys.some((key) => key.name === name)}
                            placeholder='Enter name...'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            {RESOLVER_GROUP_KEY_EXISTS_MESSAGES}
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='footer'>
                <Button
                    disabled={!name || keys.some((key) => key.name === name)}
                    size='sm'
                    onClick={handleSubmit}
                >
                    Add Key
                </Button>
                <Button size='sm' variant='secondary' onClick={() => isVisible(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};
export default FacManKeyAdd;
