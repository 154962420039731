import React, { useEffect, useRef, useState } from 'react';
import { RootState } from '../../context/rootReducer';
import { useSelector } from 'react-redux';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Spinner from 'react-bootstrap/Spinner';
import Select, { components } from 'react-select';
import { IconContext } from 'react-icons';
import { RiShieldCheckLine, RiInformationFill } from 'react-icons/ri';
import { FaTimes, FaExpandAlt, FaArrowLeft, FaArrowRight } from 'react-icons/fa';
import { MdFileDownload } from 'react-icons/md';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import Carousel from 'react-multi-carousel';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useTimeoutFn, useToggle } from 'react-use';
import { useAppDispatch } from '../../context/store';
import { getUserSites } from '../../slices/userProfile/userProfileSlice';
import {
    getOnsiteResponders,
    exportOnsiteResponders,
    responderType,
    filterResponders,
    clearFilteredResponders,
    clearCsvString
} from '../../slices/responderDashboard/responderDashboardSlice';
import { adminDirectoryLinkFormatter } from '../../components/Tables/TableFormatters';
import {
    RESPONDER_DASHBOARD_REFRESH_MS_TIME,
    RESPONDER_DASHBOARD_REFRESH_S_TIME,
    RESPONDER_REFRESH_BUFFER_SECONDS,
    SIXTY_SECONDS,
    ONE_SECOND,
    RESPONDER_STRINGS
} from '../../lib/constants';
import { getResponderExportFileTitle, getStageEnv } from '../../lib/helpers/helpers';
import 'react-multi-carousel/lib/styles.css';
import './OSERFullScreenStyles.css';
import './OSERStyles.css';
import { useHistory } from 'react-router-dom';

type option = {
    value: string;
    label: string;
};

const {
    EMPLOYEE_INFORMATION,
    MANAGER_INFORMATION,
    MANAGER,
    MANAGER_ALIAS,
    BADGE,
    LAST_BADGE_POINT,
    LAST_BADGE_TIME,
    SEE_ALL,
    SITE_POPOVER_MESSAGE,
    WAIT_MESSAGE,
    NO_INFO_MAIN,
    PAGE_INFO_MESSAGE,
    NO_RESULT_BASE_MESSAGE,
    NO_RESULT_AVAILABLE_MESSAGE,
    MAIN_TITLE,
    IMT_SECTION_TITLE,
    WHS_SECTION_TITLE,
    FIRST_RESPONDERS_SECTION_TITLE,
    IMT,
    WHS,
    FIRST_RESPONDERS,
    MAXIMUM_SITES,
    DISPLAY_FULLSCREEN,
    EXPORT,
    FULL_EXPORT,
    FILTERED_EXPORT,
    GO_BACK
} = RESPONDER_STRINGS;

/*
    Carousel props applied according to screen width.
    Base width for one Responder card is 400px or less with a card added for each additional 300px.
*/
export const responsiveDirectives = {
    teraWideDesktop: {
        breakpoint: { max: 3900, min: 3400 },
        items: 12,
        slidesToSlide: 11
    },
    gigaWideDesktop: {
        breakpoint: { max: 3400, min: 3100 },
        items: 11,
        slidesToSlide: 10
    },
    megaWideDesktop: {
        breakpoint: { max: 3100, min: 2800 },
        items: 10,
        slidesToSlide: 9
    },
    ultraWideDesktop: {
        breakpoint: { max: 2800, min: 2500 },
        items: 9,
        slidesToSlide: 8
    },
    wideDesktop: {
        breakpoint: { max: 2500, min: 2200 },
        items: 8,
        slidesToSlide: 7
    },
    largeDesktop: {
        breakpoint: { max: 2200, min: 1900 },
        items: 7,
        slidesToSlide: 6
    },
    desktop: {
        breakpoint: { max: 1900, min: 1600 },
        items: 6,
        slidesToSlide: 5
    },
    laptop: {
        breakpoint: { max: 1600, min: 1300 },
        items: 5,
        slidesToSlide: 4
    },
    smallLaptop: {
        breakpoint: { max: 1300, min: 1000 },
        items: 4,
        slidesToSlide: 3
    },
    largeTablet: {
        breakpoint: { max: 1000, min: 700 },
        items: 3,
        slidesToSlide: 2
    },
    tablet: {
        breakpoint: { max: 700, min: 400 },
        items: 2,
        slidesToSlide: 1
    },
    mobile: {
        breakpoint: { max: 400, min: 0 },
        items: 1,
        slidesToSlide: 1
    }
};

/* Carousel props applied accross all screen widths */
const carouselProps = (autoPlay: boolean): object => ({
    infinite: false,
    draggable: true,
    swipeable: true,
    arrows: false,
    renderButtonGroupOutside: true,
    showDots: false,
    autoPlay,
    pauseOnHover: true,
    autoPlaySpeed: 6000,
    shouldResetAutoplay: true,
    keyBoardControl: true,
    partialVisible: true,
    rewind: true,
    rewindWithAnimation: true
});

const getTooltip = (tipId: string): JSX.Element => {
    return (
        <ReactTooltip
            id={tipId}
            variant='light'
            place='top'
            offset={-10}
            opacity={1}
            className='card-tooltip'
            delayShow={1500} //ms
        />
    );
};

const infoCard = (
    responder: responderType,
    key: number,
    responderType: string,
    handleCardClick: any
): JSX.Element => {
    const { firstName, lastName, title, login, sites, imgURL, jobLevel } = responder;

    return (
        <div
            className='responder-card'
            key={`${responderType}-${key}`}
            onClick={() => handleCardClick(responder)}
        >
            <img className='responder-card-img' src={imgURL} />
            <div className='responder-info'>
                <div
                    className='responder-info-name'
                    data-tooltip-id={`${login}${responderType}name`}
                    data-tooltip-content={`${firstName} ${lastName}`}
                >
                    {getTooltip(`${login}${responderType}name`)}
                    <p className='card-text ellipsis-text'>{`${firstName} ${lastName}`}</p>
                </div>
                <div
                    className='responder-info-title'
                    data-tooltip-id={`${login}${responderType}title`}
                    data-tooltip-content={jobLevel ? `L${jobLevel} | ${title}` : `${title}`}
                >
                    {getTooltip(`${login}${responderType}title`)}
                    {jobLevel ? (
                        <>
                            <p className='card-text level-text'>{`L${jobLevel}`}</p>
                            <span className='responder-info-pipe' />
                        </>
                    ) : (
                        <></>
                    )}

                    <p className='card-text ellipsis-text'>{`${title}`}</p>
                </div>
                <div className='responder-info-login-sites'>
                    <p
                        className='card-text'
                        data-tooltip-id={`${login}${responderType}login`}
                        data-tooltip-content={login}
                    >
                        {adminDirectoryLinkFormatter(login, '@')}
                    </p>
                    <span className='responder-info-pipe' />
                    <p
                        className='card-text ellipsis-text'
                        data-tooltip-id={`${login}${responderType}sites`}
                        data-tooltip-content={sites.join(', ')}
                    >
                        {sites.join(', ')}
                    </p>
                    {getTooltip(`${login}${responderType}login`)}
                    {getTooltip(`${login}${responderType}sites`)}
                </div>
            </div>
        </div>
    );
};

const ProfileView: React.FC<any> = (props): JSX.Element => {
    const { responder, onClose } = props;
    const {
        firstName,
        lastName,
        jobLevel,
        title,
        login,
        imgURL,
        sites,
        manager,
        managerFirstName,
        managerLastName,
        badgeColor,
        deviceName,
        eventTimestamp
    } = responder;
    return (
        <div className='profile-view'>
            <div className='profile-view-close-wrapper'>
                <button
                    onClick={onClose}
                    style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        backgroundColor: 'transparent',
                        border: 'none'
                    }}
                >
                    <FaTimes style={{ height: '16px' }} />
                </button>
            </div>

            <div className='profile-view-responder-box'>
                <img className='profile-view-emp-img' src={imgURL} />
                <div className='profile-view-responder-info'>
                    <div className='profile-view-name-text'>
                        <p className='card-text'>{`${firstName} ${lastName}`}</p>
                    </div>
                    <div className='profile-view-title-text'>
                        <p className='card-text'>{`${title}`}</p>
                    </div>
                    <div className='profile-view-login-sites'>
                        <p className='card-text'>{adminDirectoryLinkFormatter(login, '@')}</p>
                        <span className='profile-view-info-pipe' />
                        <p className='card-text'>{sites.join(', ')}</p>
                    </div>
                </div>
            </div>

            <div className='profile-view-info-box capitalize'>
                <h4 className='profile-view-section-title'>{EMPLOYEE_INFORMATION}</h4>
                <div className='profile-view-info-row'>
                    <div className='profile-view-info-row-col'>
                        <p className='profile-view-info-key card-text'>{'Level'}</p>
                        <p className='profile-view-info-value card-text'>{`${jobLevel || ''}`}</p>
                    </div>
                    <div className='profile-view-info-row-col'>
                        <p className='profile-view-info-key card-text'>{'Badge color'}</p>
                        <p className='profile-view-info-value card-text'>{`${badgeColor.toLowerCase()} Badge`}</p>
                    </div>
                </div>
            </div>

            <div className='profile-view-info-box'>
                <h4 className='profile-view-section-title'>{MANAGER_INFORMATION}</h4>
                <div className='profile-view-info-row'>
                    <div className='profile-view-info-row-col'>
                        <p className='profile-view-info-key card-text'>{MANAGER}</p>
                        <p className='profile-view-info-value card-text'>{`${managerFirstName} ${managerLastName}`}</p>
                    </div>
                    <div className='profile-view-info-row-col'>
                        <p className='profile-view-info-key card-text'>{MANAGER_ALIAS}</p>
                        <p className='profile-view-info-value card-text'>{manager}</p>
                    </div>
                </div>
            </div>

            <div className='profile-view-info-box'>
                <h4 className='profile-view-section-title'>{BADGE}</h4>
                <div className='profile-view-info-row'>
                    <div className='profile-view-info-row-col'>
                        <p className='profile-view-info-key card-text'>{LAST_BADGE_POINT}</p>
                        <p className='profile-view-info-value card-text'>{deviceName}</p>
                    </div>
                    <div className='profile-view-info-row-col'>
                        <p className='profile-view-info-key card-text'>{LAST_BADGE_TIME}</p>
                        <p className='profile-view-info-value card-text'>{eventTimestamp}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const SeeAllView: React.FC<any> = (props): JSX.Element => {
    return (
        <div className='see-all-view-wrapper'>
            <div className='see-all-card-container'>{props.cards}</div>
            <div className='see-all-button-group'>
                <button className='see-all-back-button' onClick={props.goBackHandler}>
                    {GO_BACK}
                </button>
            </div>
        </div>
    );
};

const ButtonGroup = (props: any): JSX.Element => {
    const { next, previous, setScroll, setSeeAll, cardType, setSeeAllViewCardType, ...rest } = props;
    const {
        carouselState: { currentSlide, totalItems, slidesToShow }
    } = rest;

    const endReached = currentSlide + slidesToShow >= totalItems;
    const canScroll = totalItems > slidesToShow;
    setScroll(canScroll);

    return canScroll ? (
        <div className='carousel-custom-button-group'>
            <button
                className='carousel-custom-seeall carousel-custom-button-seeall'
                onClick={() => {
                    setSeeAll(true);
                    setSeeAllViewCardType(cardType);
                    window.history.pushState(null, '', `${window.location.pathname}/see-all`);
                }}
            >
                {SEE_ALL}
            </button>
            <button
                className={`carousel-custom-button carousel-custom-button-back ${
                    currentSlide === 0 || !canScroll ? 'disable' : ''
                }`}
                onClick={() => previous()}
            >
                <FaArrowLeft />
            </button>
            <button
                className={`carousel-custom-button carousel-custom-button-forward ${
                    endReached || !canScroll ? 'disable' : ''
                }`}
                onClick={() => next()}
            >
                <FaArrowRight />
            </button>
        </div>
    ) : (
        <div />
    );
};

const renderPopover = (props: any, content: string): JSX.Element => {
    return (
        <Popover id={'site-popover'} {...props}>
            <Popover.Content>{content}</Popover.Content>
        </Popover>
    );
};

const OnsiteResponderDashboard: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const fullscreenHandle = useFullScreenHandle();
    const history = useHistory();
    const [selectedSites, setSelectedSites] = useState<option[]>([]);
    const [isDisplayingSitePopover, setIsDisplayingSitePopover] = useToggle(false);
    const [searchText, setSearchText] = useState<string>('');
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [scrollImt, setScrollImt] = useState<boolean>(false);
    const [scrollWhs, setScrollWhs] = useState<boolean>(false);
    const [scrollFirstResponder, setScrollFirstResponder] = useState<boolean>(false);
    const [refreshElapsedTime, setRefreshElapsedTime] = useState<number>(0);
    const [showProfile, setShowProfile] = useState<boolean>(false);
    const [showSeeAll, setShowSeeAll] = useState<boolean>(false);
    const [showExportOptions, setShowExportOptions] = useState<boolean>(false);
    const [seeAllCardTypeToShow, setSeeAllCardTypeToShow] = useState<string>('');
    const [responderProfileInfo, setResponderProfileInfo] = useState<responderType | null>(null);
    const [showTitleTextTip, setShowTitleTextTip] = useState<boolean>(false);
    const { userSites, isGettingUserSites } = useSelector((state: RootState) => state.userProfile);
    const {
        responders,
        filteredResponders,
        lastRefresh,
        isGettingResponderDashboard,
        csvString
    } = useSelector((state: RootState) => state.responderDashboard);
    const profileRef = useRef<HTMLDivElement>(null); // ref used to close profile view when clicking off
    const exportButtonRef = useRef<HTMLDivElement>(null); // ref used to override export menu close behavior
    const exportMenuRef = useRef<HTMLDivElement>(null); // ref used to close export menu when clicking off
    const env = getStageEnv();
    const selectStyles = {
        valueContainer: (base: any) => ({
            ...base,
            padding: '0px 15px'
        }),
        control: (base: any) => ({
            ...base,
            backgroundColor: '#f5f5f5'
        }),
        menu: (base: any) => ({
            ...base,
            zIndex: '2000'
        }),
        menuList: (base: any) => ({
            ...base,
            zIndex: '2000'
        })
    };

    const refreshResponders = (): void => {
        const siteNames = selectedSites.map((siteOption) => siteOption.value);
        if (siteNames && siteNames.length) {
            dispatch(getOnsiteResponders(siteNames, true));
        }
        setRefreshElapsedTime(0);
    };

    const [isReady, _, resetRefresh] = useTimeoutFn(refreshResponders, RESPONDER_DASHBOARD_REFRESH_MS_TIME);

    if (isReady()) {
        resetRefresh();
    }

    const Option = (props: any): JSX.Element => {
        return (
            <div>
                <components.Option {...props} data-testid={`select-option-${props.label}`}>
                    <input
                        className='mr-2'
                        type='checkbox'
                        checked={props.isSelected}
                        onChange={() => null}
                        data-testid={`select-input-${props.label}`}
                    />
                    <label data-testid={`select-label-${props.label}`}>{props.label}</label>
                </components.Option>
            </div>
        );
    };

    const handleFullScreen = (): void => {
        fullscreenHandle.active ? fullscreenHandle.exit() : fullscreenHandle.enter();
    };

    const handleSiteSelection = (selected: any): void => {
        if (selected?.length > MAXIMUM_SITES) {
            setIsDisplayingSitePopover(true);
        } else {
            setIsDisplayingSitePopover(false);
            setSelectedSites(selected || []);
            if (showSeeAll) {
                history.goBack();
            }
        }
    };

    const handleSearch = (searchInput: string, actionType: { action: string }): void => {
        switch (actionType.action) {
            case 'input-change':
                searchInput ? setSearchText(searchInput) : setSearchText('');
                break;
            case 'clear':
                setSearchText('');
                setShowProfile(false);
                break;
            default:
                break;
        }
    };

    const handleCardClick = (responder: responderType): void => {
        if (!fullscreenHandle.active) {
            setResponderProfileInfo(responder);
            setShowProfile(true);
        }
    };

    const handleProfileClose = (): void => {
        setShowProfile(false);
        setResponderProfileInfo(null);
    };

    const handleSeeAllGoBack = (): void => {
        setShowSeeAll(false);
        setSeeAllCardTypeToShow('');
        history.goBack();
    };

    const handleResponderCsvExport = async (filtered = false): Promise<void> => {
        const siteNames = selectedSites.map((siteOption) => siteOption.value);
        if (filtered) {
            const filters: Record<string, string> = {
                searchText
            };

            if (seeAllCardTypeToShow) {
                filters.responderType = seeAllCardTypeToShow;
            }

            dispatch(exportOnsiteResponders(siteNames, filters));
        } else {
            dispatch(exportOnsiteResponders(siteNames));
        }
    };

    const handleResponderCsvDownload = (): void => {
        setShowExportOptions(false);
        const csvBlob = new Blob([csvString || ''], { type: 'text/csv' });
        const csvUrl = URL.createObjectURL(csvBlob);
        const csvLink = document.createElement('a');
        csvLink.href = csvUrl;
        csvLink.download = getResponderExportFileTitle(selectedSites.map((siteOption) => siteOption.value));
        csvLink.click();
        URL.revokeObjectURL(csvUrl);
        csvLink.remove();
        dispatch(clearCsvString());
    };

    const seeAllCardSwitch = (type: string): JSX.Element[] | null | undefined => {
        switch (type) {
            case IMT:
                return getImtCards();
            case WHS:
                return getWhsCards();
            case FIRST_RESPONDERS:
                return getFirstResponderCards();
            default:
                break;
        }
    };

    const mainTitleSwitch = (type: string): string => {
        switch (type) {
            case IMT:
                return IMT_SECTION_TITLE;
            case WHS:
                return WHS_SECTION_TITLE;
            case FIRST_RESPONDERS:
                return FIRST_RESPONDERS_SECTION_TITLE;
            default:
                return MAIN_TITLE;
        }
    };

    const totalResponderCount = (): string | null => {
        if (responders) {
            let total = 0;
            if (responders.firstResponderCount) {
                total += responders.firstResponderCount;
            }
            if (responders.imtCount) {
                total += responders.imtCount;
            }
            if (responders.whsCount) {
                total += responders.whsCount;
            }
            return `${total}`;
        } else {
            return null;
        }
    };

    const getResponderCount = (type: string): string | null => {
        switch (type) {
            case IMT:
                return `${responders?.imtCount || null}`;
            case WHS:
                return `${responders?.whsCount || null}`;
            case FIRST_RESPONDERS:
                return `${responders?.firstResponderCount || null}`;
            default:
                return totalResponderCount();
        }
    };

    const shouldDisplayImtCards = (): number | null => {
        if (isSearching) {
            return filteredResponders && filteredResponders.imtCount;
        } else {
            return responders && responders.imtCount;
        }
    };

    const shouldDisplayWhsCards = (): number | null => {
        if (isSearching) {
            return filteredResponders && filteredResponders.whsCount;
        } else {
            return responders && responders.whsCount;
        }
    };

    const shouldDisplayFirstResponders = (): number | null => {
        if (isSearching) {
            return filteredResponders && filteredResponders.firstResponderCount;
        } else {
            return responders && responders.firstResponderCount;
        }
    };

    const isNoRespondersAndNotFetching = (): boolean => {
        return (
            !isGettingResponderDashboard &&
            !isGettingUserSites &&
            (!responders || !Object.keys(responders).length)
        );
    };

    /*
        sort function for responders descending by timestamp (latest badging to the front)
    */
    const cardSortFunction = (a: responderType, b: responderType): number => {
        const timeA = a?.eventTimestamp ? new Date(a.eventTimestamp).getTime() : 0;
        const timeB = b?.eventTimestamp ? new Date(b.eventTimestamp).getTime() : 0;

        return timeB - timeA;
    };

    const getImtCards = (): JSX.Element[] | null => {
        if (isSearching) {
            return (
                filteredResponders &&
                Object.values(filteredResponders.imt)
                    .sort(cardSortFunction)
                    .map((responder: any, index: number) =>
                        infoCard(responder, index, 'imt', handleCardClick)
                    )
            );
        } else {
            return (
                responders &&
                Object.values(responders.imt)
                    .sort(cardSortFunction)
                    .map((responder: any, index: number) =>
                        infoCard(responder, index, 'imt', handleCardClick)
                    )
            );
        }
    };

    const getWhsCards = (): JSX.Element[] | null => {
        if (isSearching) {
            return (
                filteredResponders &&
                Object.values(filteredResponders.whs)
                    .sort(cardSortFunction)
                    .map((responder: any, index: number) =>
                        infoCard(responder, index, 'whs', handleCardClick)
                    )
            );
        } else {
            return (
                responders &&
                Object.values(responders.whs)
                    .sort(cardSortFunction)
                    .map((responder: any, index: number) =>
                        infoCard(responder, index, 'whs', handleCardClick)
                    )
            );
        }
    };

    const getFirstResponderCards = (): JSX.Element[] | null => {
        if (isSearching) {
            return (
                filteredResponders &&
                Object.values(filteredResponders.firstResponders)
                    .sort(cardSortFunction)
                    .map((responder: any, index: number) =>
                        infoCard(responder, index, 'firstResponder', handleCardClick)
                    )
            );
        } else {
            return (
                responders &&
                Object.values(responders.firstResponders)
                    .sort(cardSortFunction)
                    .map((responder: any, index: number) =>
                        infoCard(responder, index, 'firstResponder', handleCardClick)
                    )
            );
        }
    };

    const displaySitePopover = (props: any): JSX.Element => {
        return selectedSites.length >= MAXIMUM_SITES ? renderPopover(props, SITE_POPOVER_MESSAGE) : <div />;
    };

    /*
        Get facilities list on page render
    */
    useEffect(() => {
        dispatch(getUserSites());
    }, []);

    /*
        Override browser back button to exit SeeAll view instead of going to previous page
    */
    useEffect(() => {
        const handlePopState = (): void => {
            setShowSeeAll(false);
            setSeeAllCardTypeToShow('');
        };

        window.addEventListener('popstate', handlePopState);

        return () => {
            window.removeEventListener('popstate', handlePopState);
        };
    }, []);

    /*
        Handle site selections.
    */
    useEffect(() => {
        const siteNames = selectedSites.map((siteOption) => siteOption.value);
        resetRefresh();
        setRefreshElapsedTime(0);
        handleProfileClose();
        dispatch(getOnsiteResponders(siteNames));
    }, [selectedSites]);

    /*
        Control searching based on character count
    */
    useEffect(() => {
        if (searchText.length) {
            dispatch(filterResponders(responders, searchText));
            setIsSearching(true);
        } else {
            dispatch(clearFilteredResponders());
            setIsSearching(false);
        }
        if (!responders) {
            setSearchText('');
            setIsSearching(false);
        }
    }, [searchText, responders]);

    /*
        Updates "Last refresh" time displayed to user
    */
    useEffect(() => {
        const timer = setTimeout(() => {
            const timeDiff = lastRefresh === 0 ? 0 : Math.round((Date.now() - lastRefresh) / ONE_SECOND);
            setRefreshElapsedTime(timeDiff);
        }, 5000);
        return () => clearTimeout(timer);
    });

    /*
        Close profile view when clicking off profile.
    */
    useEffect(() => {
        const handler = (event: MouseEvent): void => {
            if (!profileRef?.current?.contains(event.target as Node)) {
                handleProfileClose();
            }
        };

        document.addEventListener('mousedown', handler);

        return () => document.removeEventListener('mousedown', handler);
    }, [profileRef.current]);

    /*
        Close profile view when clicking off profile.
    */
    useEffect(() => {
        const handler = (event: MouseEvent): void => {
            if (
                !exportMenuRef?.current?.contains(event.target as Node) &&
                !exportButtonRef?.current?.contains(event.target as Node)
            ) {
                setShowExportOptions(false);
            }
        };

        document.addEventListener('mousedown', handler);

        return () => document.removeEventListener('mousedown', handler);
    }, [exportMenuRef.current, exportButtonRef.current]);

    /*
        Trigger CSV download when CSV string is available
    */
    useEffect(() => {
        if (csvString) {
            handleResponderCsvDownload();
        }
    }, [csvString]);

    return (
        <Container
            fluid
            id='dashboard-body'
            className={isNoRespondersAndNotFetching() ? '' : 'dashboard-body-displaying'}
            data-testid='oser-dash-container'
        >
            <div id='fullscreen-wrapper' data-testid='fullscreen-wrapper'>
                <FullScreen handle={fullscreenHandle} data-testid='oser-dash-fullscreen'>
                    {fullscreenHandle.active && (
                        <div className='fullscreen-view-close-wrapper'>
                            <div>
                                <IconContext.Provider value={{ color: '#5DBEFF', size: '50px' }}>
                                    <RiShieldCheckLine />
                                </IconContext.Provider>
                                <strong className='text-wrap rp-banner'>Rally Point Web {env}</strong>
                            </div>
                            <button
                                onClick={handleFullScreen}
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    padding: '0 20px 20px 0'
                                }}
                            >
                                <FaTimes style={{ height: '26px' }} />
                            </button>
                        </div>
                    )}

                    <div id='oser-dash-main-title-fullscreen-btn-wrapper'>
                        <div id='oser-dash-main-title-wrapper'>
                            <div
                                id='oser-dash-main-title-count-separator'
                                data-testid='oser-dash-main-title-count-separator'
                            >
                                <h3 id='oser-dash-main-title' data-testid='oser-dash-main-title'>
                                    {mainTitleSwitch(seeAllCardTypeToShow)}
                                </h3>
                                {getResponderCount(seeAllCardTypeToShow) ? (
                                    <h3
                                        className='responder-type-title-count'
                                        data-testid='oser-dash-main-title-count'
                                    >
                                        {`${getResponderCount(seeAllCardTypeToShow) || 0}`}
                                    </h3>
                                ) : (
                                    <div />
                                )}
                            </div>
                            {getResponderCount(seeAllCardTypeToShow) ? (
                                <>
                                    <div className='last-refresh-wrapper'>
                                        <span
                                            className={`refresh-dot ${
                                                refreshElapsedTime >
                                                RESPONDER_DASHBOARD_REFRESH_S_TIME +
                                                    RESPONDER_REFRESH_BUFFER_SECONDS
                                                    ? 'red-dot'
                                                    : 'green-dot'
                                            }`}
                                        ></span>
                                        <p
                                            className='last-refresh'
                                            data-testid='oser-dash-main-title-last-refresh'
                                        >
                                            {refreshElapsedTime <= RESPONDER_DASHBOARD_REFRESH_S_TIME
                                                ? `Last refresh ${refreshElapsedTime}s ago`
                                                : `Last refresh ${Math.floor(
                                                      refreshElapsedTime / SIXTY_SECONDS
                                                  )}m ago`}
                                        </p>
                                    </div>
                                </>
                            ) : (
                                <div />
                            )}
                            <div
                                className='title-info-icon'
                                onMouseEnter={() => setShowTitleTextTip(true)}
                                onMouseLeave={() => setShowTitleTextTip(false)}
                            >
                                <RiInformationFill style={{ height: '24px', width: '24px' }} />
                                {showTitleTextTip && (
                                    <span className='title-info-text'>
                                        <div className='info-text-content'>{PAGE_INFO_MESSAGE}</div>
                                        <div className='info-text-arrow' />
                                    </span>
                                )}
                            </div>
                        </div>

                        {getResponderCount(seeAllCardTypeToShow) && !fullscreenHandle.active ? (
                            <div id='responder-options-menu' data-testid='responder-options-menu'>
                                <div ref={exportButtonRef} id='export-btn-wrapper'>
                                    <button
                                        id='export-btn'
                                        data-testid='export-btn'
                                        onClick={() => setShowExportOptions(!showExportOptions)}
                                    >
                                        <MdFileDownload
                                            style={{
                                                height: '20px',
                                                width: '20px'
                                            }}
                                        />
                                        {EXPORT}
                                    </button>
                                </div>
                                {showExportOptions ? (
                                    <div id='export-option-csv-wrapper' ref={exportMenuRef}>
                                        <ul className='export-options-button-menu'>
                                            <li>
                                                <button
                                                    className='export-btn-csv'
                                                    onClick={() => handleResponderCsvExport()}
                                                >
                                                    {FULL_EXPORT}
                                                </button>
                                            </li>
                                            <li>
                                                <button
                                                    className='export-btn-csv'
                                                    onClick={() => handleResponderCsvExport(true)}
                                                >
                                                    {FILTERED_EXPORT}
                                                </button>
                                            </li>
                                        </ul>
                                    </div>
                                ) : (
                                    <div />
                                )}
                                <button
                                    id='fs-expand-btn'
                                    onClick={handleFullScreen}
                                    data-testid='oser-dash-fullscreen-open-btn'
                                >
                                    <FaExpandAlt
                                        style={{
                                            color: 'white',
                                            marginRight: '10px'
                                        }}
                                        data-testid='oser-dash-fullscreen-open-icon'
                                    />
                                    {DISPLAY_FULLSCREEN}
                                </button>
                            </div>
                        ) : (
                            <div />
                        )}
                    </div>

                    {!fullscreenHandle.active ? (
                        <div id='oser-dash-select-menu-wrapper' data-testid='oser-dash-select-menu-wrapper'>
                            <div id='facility-select-box' data-testid='oser-dash-facility-select-box'>
                                <OverlayTrigger
                                    placement='auto'
                                    show={isDisplayingSitePopover}
                                    overlay={displaySitePopover}
                                    rootClose
                                    onToggle={setIsDisplayingSitePopover}
                                >
                                    <Select
                                        options={userSites}
                                        onChange={handleSiteSelection}
                                        placeholder={
                                            isGettingUserSites ? `Loading Facilities...` : 'Facilities'
                                        }
                                        value={selectedSites}
                                        components={{ Option }}
                                        hideSelectedOptions={false}
                                        closeMenuOnSelect={selectedSites.length === MAXIMUM_SITES}
                                        isMulti
                                        styles={selectStyles}
                                        data-testid='oser-dash-facility-select'
                                    />
                                </OverlayTrigger>
                            </div>

                            {responders ? (
                                <div id='search-select-box' data-testid='oser-dash-search-select-box'>
                                    <Select
                                        onInputChange={handleSearch}
                                        onChange={handleSearch}
                                        placeholder={`Search Responders...`}
                                        inputValue={searchText}
                                        value={searchText}
                                        isSearchable={true}
                                        components={{
                                            Menu: () => null,
                                            MenuList: () => null, // display list below search
                                            DropdownIndicator: () => null,
                                            IndicatorSeparator: () => null
                                        }}
                                        hideSelectedOptions={false}
                                        isClearable
                                        styles={selectStyles}
                                        data-testid='oser-dash-search-select'
                                    />
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                    ) : (
                        <div />
                    )}

                    {!showSeeAll ? (
                        <div id={'dashboard-body-div'}>
                            {shouldDisplayImtCards() ? (
                                <div
                                    className='carousel-wrapper'
                                    data-testid='oser-dash-imt-carousel-wrapper'
                                >
                                    <div
                                        className='responder-type-title'
                                        data-testid='oser-dash-imt-responder-type-title-count-wrapper'
                                    >
                                        <h3
                                            className='responder-type-title-text'
                                            data-testid='oser-dash-imt-responder-type-title'
                                        >
                                            {IMT_SECTION_TITLE}
                                        </h3>
                                        <h3
                                            className='responder-type-title-count'
                                            data-testid='oser-dash-imt-responder-type-title-count'
                                        >
                                            {`${
                                                isSearching
                                                    ? filteredResponders?.imtCount
                                                    : responders?.imtCount
                                            }`}
                                        </h3>
                                    </div>
                                    <div className='carouselBase' data-testid='oser-dash-imt-carousel-base'>
                                        <Carousel
                                            responsive={responsiveDirectives}
                                            {...carouselProps(fullscreenHandle.active)}
                                            data-testid='oser-dash-imt-carousel'
                                            customButtonGroup={
                                                fullscreenHandle.active ? (
                                                    <div />
                                                ) : (
                                                    <ButtonGroup
                                                        setScroll={setScrollImt}
                                                        setSeeAll={setShowSeeAll}
                                                        cardType={IMT}
                                                        setSeeAllViewCardType={setSeeAllCardTypeToShow}
                                                        getCards={getImtCards}
                                                    />
                                                )
                                            }
                                            sliderClass={`responder-multi-carousel-track ${
                                                !scrollImt ? 'no-transform' : ''
                                            }`}
                                        >
                                            {getImtCards()}
                                        </Carousel>
                                    </div>
                                </div>
                            ) : (
                                <div />
                            )}

                            {shouldDisplayWhsCards() ? (
                                <div
                                    className='carousel-wrapper'
                                    data-testid='oser-dash-whs-carousel-wrapper'
                                >
                                    <div
                                        className='responder-type-title'
                                        data-testid='oser-dash-whs-responder-type-title-count-wrapper'
                                    >
                                        <h3
                                            className='responder-type-title-text'
                                            data-testid='oser-dash-whs-responder-type-title'
                                        >
                                            {WHS_SECTION_TITLE}
                                        </h3>
                                        <h3
                                            className='responder-type-title-count'
                                            data-testid='oser-dash-whs-responder-type-title-count'
                                        >
                                            {`${
                                                isSearching
                                                    ? filteredResponders?.whsCount
                                                    : responders?.whsCount
                                            }`}
                                        </h3>
                                    </div>
                                    <div className='carouselBase' data-testid='oser-dash-whs-carousel-base'>
                                        <Carousel
                                            responsive={responsiveDirectives}
                                            {...carouselProps(fullscreenHandle.active)}
                                            data-testid='oser-dash-whs-carousel'
                                            customButtonGroup={
                                                fullscreenHandle.active ? (
                                                    <div />
                                                ) : (
                                                    <ButtonGroup
                                                        setScroll={setScrollWhs}
                                                        setSeeAll={setShowSeeAll}
                                                        cardType={WHS}
                                                        setSeeAllViewCardType={setSeeAllCardTypeToShow}
                                                        getCards={getWhsCards}
                                                    />
                                                )
                                            }
                                            sliderClass={`responder-multi-carousel-track ${
                                                !scrollWhs ? 'no-transform' : ''
                                            }`}
                                        >
                                            {getWhsCards()}
                                        </Carousel>
                                    </div>
                                </div>
                            ) : (
                                <div />
                            )}

                            {shouldDisplayFirstResponders() ? (
                                <div
                                    className='carousel-wrapper'
                                    data-testid='oser-dash-first-responder-carousel-wrapper'
                                >
                                    <div
                                        className='responder-type-title'
                                        data-testid='oser-dash-first-responder-responder-type-title-count-wrapper'
                                    >
                                        <h3
                                            className='responder-type-title-text'
                                            data-testid='oser-dash-first-responder-responder-type-title'
                                        >
                                            {FIRST_RESPONDERS_SECTION_TITLE}
                                        </h3>
                                        <h3
                                            className='responder-type-title-count'
                                            data-testid='oser-dash-first-responder-responder-type-title-count'
                                        >
                                            {`${
                                                isSearching
                                                    ? filteredResponders?.firstResponderCount
                                                    : responders?.firstResponderCount
                                            }`}
                                        </h3>
                                    </div>
                                    <div
                                        className='carouselBase'
                                        data-testid='oser-dash-first-responder-carousel-base'
                                    >
                                        <Carousel
                                            responsive={responsiveDirectives}
                                            {...carouselProps(fullscreenHandle.active)}
                                            data-testid='oser-dash-first-responder-carousel'
                                            customButtonGroup={
                                                fullscreenHandle.active ? (
                                                    <div />
                                                ) : (
                                                    <ButtonGroup
                                                        setScroll={setScrollFirstResponder}
                                                        setSeeAll={setShowSeeAll}
                                                        cardType={FIRST_RESPONDERS}
                                                        setSeeAllViewCardType={setSeeAllCardTypeToShow}
                                                        getCards={getFirstResponderCards}
                                                    />
                                                )
                                            }
                                            sliderClass={`responder-multi-carousel-track ${
                                                !scrollFirstResponder ? 'no-transform' : ''
                                            }`}
                                        >
                                            {getFirstResponderCards()}
                                        </Carousel>
                                    </div>
                                </div>
                            ) : (
                                <div />
                            )}
                        </div>
                    ) : (
                        <div>
                            <SeeAllView
                                cards={seeAllCardSwitch(seeAllCardTypeToShow)}
                                goBackHandler={handleSeeAllGoBack}
                            />
                        </div>
                    )}
                </FullScreen>
            </div>
            {isNoRespondersAndNotFetching() ? (
                <div id='no-responders-message'>
                    <p id='no-responders-message-title'>{NO_INFO_MAIN}</p>
                    {selectedSites.length ? (
                        <p className='no-responders-message-text'>{NO_RESULT_BASE_MESSAGE}</p>
                    ) : (
                        <div />
                    )}
                    <p className='no-responders-message-text'>{NO_RESULT_AVAILABLE_MESSAGE}</p>
                </div>
            ) : (
                <div />
            )}
            {(isGettingResponderDashboard || isGettingUserSites) && (
                <div id='responder-loading-overlay'>
                    <Spinner id='responder-loading-spinner' animation='border' />
                    <div id='responder-loading-text'>{WAIT_MESSAGE}</div>
                </div>
            )}
            {showProfile ? (
                <div ref={profileRef} id='responder-profile-overlay'>
                    <ProfileView responder={responderProfileInfo} onClose={handleProfileClose} />
                </div>
            ) : (
                <div />
            )}
        </Container>
    );
};

export default OnsiteResponderDashboard;
