import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Container from 'react-bootstrap/Container';
import { Card, Col, Row } from 'react-bootstrap';
import { RootState } from '../../context/rootReducer';
import NoPermissionsView from '../NoPermissionsView';
import DimmedLoader from 'GuardianWidgetCommons/lib/components/DimmedLoader';
import PermissionsSidebar from '../../components/Sidebar/PermissionsSidebar';
import Nav from 'react-bootstrap/Nav';
import { NavLink } from 'react-router-dom';
import { AvailableRoutes } from '../../AppRoutes';
import UserLookupDataGrid from '../../components/DataGrids/UserLookupDataGrid';
import { useAppDispatch } from '../../context/store';
import { getLatestAppVersionAsync } from '../../slices/userProfile/userProfileSlice';
import SiteLookup from './SiteLookup';
import UserLookup from './UserLookup';

type Props = {
    isUserLookup?: boolean;
    isSiteLookup?: boolean;
};

const PermissionsLookup = ({ isUserLookup, isSiteLookup }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const { userCardholderInformation, isGettingUserCardholderInformation } = useSelector(
        (state: RootState) => state.userProfile
    );
    const { isGettingUserPermissionRoles } = useSelector((state: RootState) => state.userPermissions);
    const { webRole } = useSelector((state: RootState) => state.userPermissions?.userPermissionRoles);
    const userLookupAccess = webRole !== 'User';

    useEffect(() => {
        dispatch(getLatestAppVersionAsync());
    }, []);

    return userCardholderInformation && userLookupAccess ? (
        <DimmedLoader active={isGettingUserCardholderInformation || isGettingUserPermissionRoles}>
            <Container fluid>
                <Row className='my-2'>
                    <Col md={3} lg={2}>
                        <PermissionsSidebar />
                    </Col>
                    <Col>
                        <Card
                            style={{
                                minHeight: '100vh',
                                maxHeight: '100vh',
                                overflowY: 'auto',
                                overflowX: 'hidden'
                            }}
                        >
                            <Card.Body>
                                <Nav variant='tabs' className='ml-2'>
                                    <Nav.Item>
                                        <Nav.Link
                                            as={NavLink}
                                            to={AvailableRoutes.userLookup}
                                            exact
                                            style={{ fontSize: '13pt', textAlign: 'left' }}
                                            activeStyle={{ fontWeight: 'bold' }}
                                        >
                                            Search by User
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link
                                            as={NavLink}
                                            to={AvailableRoutes.siteLookup}
                                            exact
                                            style={{ fontSize: '13pt', textAlign: 'left' }}
                                            activeStyle={{ fontWeight: 'bold' }}
                                        >
                                            Search by Site
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                                <hr />
                                {isUserLookup && <UserLookup />}
                                {isSiteLookup && <SiteLookup />}
                                <div className='pl-4 pt-5 w-75'>
                                    <UserLookupDataGrid />
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </DimmedLoader>
    ) : (
        <NoPermissionsView />
    );
};

export default PermissionsLookup;
