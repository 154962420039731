import React, { SetStateAction, useEffect, useState } from 'react';
import {
    DataGridPro,
    GridPagination,
    GridSortModel,
    GridSortDirection,
    GridToolbar
} from '@mui/x-data-grid-pro';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTimeoutFn } from 'react-use';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import { DEFAULT_TABLE_SORTING_ORDER, SIZE_PER_PAGE_LIST, TABLE_REFRESH_TIME } from '../../lib/constants';
import { formatToLocalTime } from '../../lib/helpers/helpers';
import { searchIncidentAuditTrailAsync } from '../../slices/incidents/incidentDetailsSlice';
import ToggleAutoReloadSwitch from '../Buttons/ToggleAutoReloadSwitch';
import {
    incidentAuditTrailColumns,
    incidentAuditTrailDataGridCss,
    defaultSortModel,
    DEFAULT_PAGE_SIZE
} from './IncidentAuditTrailProps';

const IncidentAuditTrailDataGrid: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const { incidentId } = useParams<{ incidentId: string }>();
    const [timestamp, setTimestamp] = useState(formatToLocalTime(DateTime.utc()));
    const [selectedPageSize, setSelectedPageSize] = React.useState(DEFAULT_PAGE_SIZE);
    const [tableSort, setTableSort] = React.useState<GridSortModel>(defaultSortModel);
    const { auditTrail, isSearchingIncidentAuditTrail } = useSelector(
        (state: RootState) => state.incidentDetails
    );

    const refreshTable = (): void => {
        dispatch(searchIncidentAuditTrailAsync({ incidentId }));
    };

    const [isReady, cancel, reset] = useTimeoutFn(refreshTable, TABLE_REFRESH_TIME);

    if (isReady()) {
        reset();
    }

    useEffect(() => {
        if (incidentId) {
            dispatch(searchIncidentAuditTrailAsync({ incidentId }));
        }
    }, []);

    useEffect(() => {
        setTimestamp(formatToLocalTime(DateTime.utc()));
    }, [auditTrail]);

    const tableSortChangeCallback = (newSort: GridSortModel): void => {
        setTableSort(newSort);
    };

    const tablePageSizeChange = (event: SetStateAction<number>): void => {
        setSelectedPageSize(event);
    };

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <h6 style={{ width: '85%' }}>Audit Trail as of {timestamp} </h6>
                <ToggleAutoReloadSwitch
                    label='For Table'
                    cancel={() => {
                        cancel();
                    }}
                    reset={() => {
                        reset();
                    }}
                />
            </div>
            <DataGridPro
                rows={auditTrail}
                columns={incidentAuditTrailColumns}
                pagination
                pageSize={selectedPageSize}
                rowsPerPageOptions={SIZE_PER_PAGE_LIST}
                sortingOrder={DEFAULT_TABLE_SORTING_ORDER as GridSortDirection[]}
                onSortModelChange={tableSortChangeCallback}
                onPageSizeChange={tablePageSizeChange}
                sortModel={tableSort}
                loading={isSearchingIncidentAuditTrail}
                autoHeight
                components={{
                    Toolbar: () => <GridToolbar />,
                    Pagination: GridPagination
                }}
                componentsProps={{
                    filterPanel: {
                        linkOperators: [],
                        filterFormProps: {
                            operatorInputProps: {
                                disabled: true,
                                sx: { display: 'none' }
                            }
                        }
                    }
                }}
                disableColumnMenu={true}
                disableSelectionOnClick
                sx={incidentAuditTrailDataGridCss}
            />
        </div>
    );
};
export default IncidentAuditTrailDataGrid;
