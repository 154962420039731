import React, { SetStateAction, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
    DataGridPro,
    GridToolbarContainer,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridToolbarDensitySelector,
    GridPagination,
    GridFilterModel,
    GridSortModel,
    GridSortDirection,
    GridFooterContainer,
    GridFooter
} from '@mui/x-data-grid-pro';
import Button from '@mui/material/Button';
import { useAppDispatch } from '../../context/store';
import { RootState } from '../../context/rootReducer';
import { convertDateToDBDatetime, addDateToFileName, dateDiffSort } from '../../lib/helpers/helpers';
import {
    incidentHistoryColumns,
    defaultSortModel,
    dateSortModel,
    incidentHistoryDataGridCss,
    buttonBaseProps,
    fullCSVFilterAttributes,
    initialSortOrder,
    DEFAULT_PAGE_SIZE
} from './IncidentsHistoryProps';
import { IncidentHistoryMultiFilterAttributes } from '../../lib/types';
import {
    SIZE_PER_PAGE_LIST,
    START_PAGE,
    DEFAULT_TABLE_SORTING_ORDER,
    TABLE_FILTER_TIP,
    FILTER_TIP_ICON_SIZE,
    RP_BLUE
} from '../../lib/constants';
import { IncidentsHistoryPropMap } from '../../lib/dictionaries/IncidentsHistoryDictionary';
import {
    downloadIncidentsHistoryCSVAsync,
    SearchIncidentsFilterAttributes,
    searchIncidentsHistoryAsync
} from '../../slices/incidentsHistory/IncidentsHistorySlice';
import { FaInfoCircle } from 'react-icons/fa';
import { Logger } from '../../lib/utils/logger';

const IncidentHistoryDataGrid: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();
    const [selectedPageSize, setSelectedPageSize] = React.useState(DEFAULT_PAGE_SIZE);
    const [page, setPage] = React.useState(START_PAGE);
    const [filters, setFilters] = React.useState<GridFilterModel>();
    const [filterTip, setFilterTip] = React.useState(false);
    const [tableSort, setTableSort] = React.useState<GridSortModel>(defaultSortModel);
    const {
        incidentsHistory,
        pageNumber,
        pageSize,
        totalCount,
        filterAttributes,
        sortOrder,
        isLoadingIncidentsHistory
    } = useSelector((state: RootState) => state.incidentsHistory);
    const { userCardholderInformation } = useSelector((state: RootState) => state.userProfile);

    useEffect(() => {
        dispatch(searchIncidentsHistoryAsync(page, selectedPageSize, filterAttributes, sortOrder));
    }, [dispatch]);

    useEffect(() => {
        tableFilterAndSortHandler();
    }, [filters, tableSort]);

    useEffect(() => {
        dispatch(searchIncidentsHistoryAsync(page, selectedPageSize, filterAttributes, sortOrder));
    }, [page, selectedPageSize]);

    const tableFilterAndSortHandler = (): void => {
        const baseFilterAttributes: SearchIncidentsFilterAttributes = {
            isOpen: false,
            closedFor: { time: 60, format: 'MINUTE' },
            timestamp: { start: null, end: null }
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const multiFilterAttributes: any = {} as IncidentHistoryMultiFilterAttributes;
        if (filters) {
            const { items } = filters;
            items.forEach(({ columnField, value }) => {
                if (value && value !== '') {
                    if (
                        columnField === IncidentsHistoryPropMap.startTime ||
                        columnField === IncidentsHistoryPropMap.endTime
                    ) {
                        let { end, start } = baseFilterAttributes.timestamp as {
                            start: number | null;
                            end: number | null;
                        };
                        if (columnField === IncidentsHistoryPropMap.startTime) {
                            start = convertDateToDBDatetime(new Date(value));

                            baseFilterAttributes['timestamp'] = { end, start };
                        } else {
                            end = convertDateToDBDatetime(new Date(value), false);
                            baseFilterAttributes['timestamp'] = { start, end };
                        }
                    } else if (
                        columnField === IncidentsHistoryPropMap.onSite ||
                        columnField === IncidentsHistoryPropMap.numberAccounted ||
                        columnField === IncidentsHistoryPropMap.percentAccounted
                    ) {
                        baseFilterAttributes[columnField] = parseInt(value as string);
                    } else if (columnField === IncidentsHistoryPropMap.eventOwner) {
                        // Filter patch to convert IC to EO from backend.
                        if (multiFilterAttributes[IncidentsHistoryPropMap.incidentCommander]) {
                            multiFilterAttributes[IncidentsHistoryPropMap.incidentCommander].push(
                                value as string
                            );
                        } else {
                            multiFilterAttributes[IncidentsHistoryPropMap.incidentCommander] = [value];
                        }
                    } else if (
                        columnField === IncidentsHistoryPropMap.id ||
                        columnField === IncidentsHistoryPropMap.siteName ||
                        columnField === IncidentsHistoryPropMap.incidentType ||
                        columnField === IncidentsHistoryPropMap.incidentNewType ||
                        columnField === IncidentsHistoryPropMap.incidentSubType
                    ) {
                        if (multiFilterAttributes[columnField]) {
                            multiFilterAttributes[columnField].push(value as string);
                        } else {
                            multiFilterAttributes[columnField] = [value];
                        }
                    } else {
                        baseFilterAttributes[columnField] = value;
                    }
                }
            });
        }
        const filterAttributes = { ...baseFilterAttributes, ...multiFilterAttributes };
        const sort = {
            column: '',
            order: ''
        };
        if (tableSort && tableSort.length) {
            sort.column =
                tableSort[0].field === IncidentsHistoryPropMap.eventOwner
                    ? IncidentsHistoryPropMap.incidentCommander
                    : tableSort[0].field;
        } else {
            sort.column = IncidentsHistoryPropMap.startTime;
        }
        if (tableSort && tableSort[0] && tableSort[0].sort === 'asc') {
            sort.order = 'desc'; // intentionally mismatched so down sort arrow orders DESC
        } else {
            sort.order = 'asc';
        }
        dispatch(searchIncidentsHistoryAsync(pageNumber, pageSize, filterAttributes, [sort]));
    };

    const tableFilterChangeCallback = (newFilters: GridFilterModel): void => {
        if (newFilters !== filters) {
            if (dateDiffSort(filters ? filters.items : [], newFilters.items)) {
                setTableSort(dateSortModel);
            }
            setPage(START_PAGE);
            setFilters(newFilters);
        }
    };

    const tableSortChangeCallback = (newSort: GridSortModel): void => {
        setPage(START_PAGE);
        setTableSort(newSort);
    };

    const tablePageHandlerCallback = (newPage: number): void => {
        newPage < START_PAGE ? setPage(START_PAGE) : setPage(newPage + START_PAGE);
    };

    const tablePageSizeChange = (event: SetStateAction<number>): void => {
        setPage(START_PAGE);
        setSelectedPageSize(event);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const CustomToolbar = (props: any): JSX.Element => {
        return (
            <GridToolbarContainer>
                <GridToolbarFilterButton {...props} />
                <GridToolbarDensitySelector {...props} />
                <GridToolbarColumnsButton {...props} />
                <Button
                    {...buttonBaseProps}
                    onClick={async () => {
                        dispatch(
                            downloadIncidentsHistoryCSVAsync(
                                addDateToFileName('IncidentsHistory.csv'),
                                fullCSVFilterAttributes,
                                initialSortOrder
                            )
                        );
                        Logger.info(
                            `${userCardholderInformation.login} is initiating a Full Export download of Incident History`,
                            {
                                employeeId: userCardholderInformation.empId,
                                userAlias: userCardholderInformation.login
                            }
                        );
                    }}
                >
                    Full Export
                </Button>
                <Button
                    {...buttonBaseProps}
                    onClick={async () => {
                        dispatch(
                            downloadIncidentsHistoryCSVAsync(
                                addDateToFileName('Filtered-IncidentsHistory.csv'),
                                filterAttributes,
                                sortOrder
                            )
                        );
                        Logger.info(
                            `${userCardholderInformation.login} is initiating a Filtered Export download of Incident History`,
                            {
                                employeeId: userCardholderInformation.empId,
                                userAlias: userCardholderInformation.login,
                                filterAttributes: filterAttributes
                            }
                        );
                    }}
                >
                    Filtered Export
                </Button>
            </GridToolbarContainer>
        );
    };

    const CustomFooter = (): JSX.Element => {
        return (
            <GridFooterContainer>
                <p style={{ marginLeft: '10px' }}>{TABLE_FILTER_TIP}</p>
                <GridFooter
                    sx={{
                        border: 'none'
                    }}
                />
            </GridFooterContainer>
        );
    };

    return (
        <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <button
                    onClick={() => setFilterTip(!filterTip)}
                    style={{ border: 'none', margin: '5px 5px 5px 0', background: 'none' }}
                >
                    <FaInfoCircle size={FILTER_TIP_ICON_SIZE} color={RP_BLUE} />
                </button>
                {filterTip && <h6 style={{ margin: '0' }}>{TABLE_FILTER_TIP}</h6>}
            </div>
            <DataGridPro
                rows={incidentsHistory}
                rowCount={totalCount}
                columns={incidentHistoryColumns}
                pagination
                pageSize={selectedPageSize}
                page={page - START_PAGE}
                rowsPerPageOptions={SIZE_PER_PAGE_LIST}
                sortingOrder={DEFAULT_TABLE_SORTING_ORDER as GridSortDirection[]}
                filterMode='server'
                sortingMode='server'
                paginationMode='server'
                onFilterModelChange={tableFilterChangeCallback}
                onSortModelChange={tableSortChangeCallback}
                onPageChange={tablePageHandlerCallback}
                onPageSizeChange={tablePageSizeChange}
                sortModel={tableSort}
                loading={isLoadingIncidentsHistory}
                autoHeight
                components={{
                    Toolbar: () => <CustomToolbar />,
                    Pagination: GridPagination,
                    Footer: CustomFooter
                }}
                componentsProps={{
                    filterPanel: {
                        linkOperators: [],
                        filterFormProps: {
                            operatorInputProps: {
                                disabled: true,
                                sx: { display: 'none' }
                            }
                        }
                    }
                }}
                disableColumnMenu={true}
                disableSelectionOnClick
                sx={incidentHistoryDataGridCss}
            />
        </div>
    );
};

export default IncidentHistoryDataGrid;
