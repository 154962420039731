import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import asyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import React from 'react';
import { toast } from 'react-toastify';
import { AppThunk } from '../../context/store';
import { SEARCH_INCIDENTS_API_ENDPOINT } from '../../lib/globals';
import { Incident } from '../../lib/types';

const { doRequest } = asyncRequest;

interface IncidentsState {
    incidents: Incident[];
    totalCount: number;
    pageSize: number;
    pageNumber: number;
    isLoadingIncidents: boolean;
    searchIncidentsError: string;
}

export const initialState: IncidentsState = {
    incidents: [],
    totalCount: 0,
    pageNumber: 1,
    pageSize: 50,
    isLoadingIncidents: false,
    searchIncidentsError: ''
};

const incidentsSlice = createSlice({
    name: 'incidents',
    initialState,
    reducers: {
        startSearchIncidents: (state) => {
            state.searchIncidentsError = '';
            state.isLoadingIncidents = true;
        },
        searchIncidentsSucceeded: (
            state,
            {
                payload
            }: PayloadAction<{
                incidents: Incident[];
            }>
        ) => {
            let { incidents } = payload;
            incidents = incidents.map((incident) => {
                const eventOwner = incident.participants.find((participant) => participant.roleId === 1);
                return {
                    eventOwner: eventOwner ? eventOwner.firstName + ' ' + eventOwner.lastName : '',
                    ...incident
                };
            });
            state.isLoadingIncidents = false;
            state.incidents = incidents;
        },
        searchIncidentsFailed: (state, { payload }: PayloadAction<{ error: string }>) => {
            const { error } = payload;
            state.isLoadingIncidents = false;
            state.searchIncidentsError = error;
        },
        resetIncidentsState: () => initialState
    }
});

export const {
    startSearchIncidents,
    searchIncidentsSucceeded,
    searchIncidentsFailed,
    resetIncidentsState
} = incidentsSlice.actions;

export default incidentsSlice.reducer;

export const searchIncidentsAsync = (): AppThunk => async (dispatch) => {
    try {
        dispatch(resetIncidentsState());
        dispatch(startSearchIncidents());
        const request = {
            pageSize: 50,
            pageNumber: 1
        };
        const closedFilterAttributes = {
            isOpen: false,
            closedFor: {
                time: 60,
                format: 'MINUTE',
                isRecent: true
            }
        };
        const openFilterAttributes = {
            isOpen: true
        };
        const incidentsSearchPromises = [
            doRequest(
                SEARCH_INCIDENTS_API_ENDPOINT,
                { ...request, filterAttributes: openFilterAttributes },
                GuardianAuth.createRequestAuthHeader()
            ),
            doRequest(
                SEARCH_INCIDENTS_API_ENDPOINT,
                { ...request, filterAttributes: closedFilterAttributes },
                GuardianAuth.createRequestAuthHeader()
            )
        ];
        const searchIncidentsResults = await Promise.all(incidentsSearchPromises);
        const { incidentData: openIncidents } = JSON.parse(searchIncidentsResults[0].data.body);
        const { incidentData: recentlyClosedIncidents } = JSON.parse(searchIncidentsResults[1].data.body);
        dispatch(
            searchIncidentsSucceeded({
                incidents: [...openIncidents, ...recentlyClosedIncidents]
            })
        );
    } catch (error) {
        dispatch(searchIncidentsFailed({ error: error.message }));
        toast.error(<ToastErrorMessage header={'Search incidents failed'} errorMessage={error.message} />);
    }
};
