import React, { SetStateAction, useEffect } from 'react';
import {
    DataGridPro,
    GridPagination,
    GridSortModel,
    GridSortDirection,
    GridToolbar
} from '@mui/x-data-grid-pro';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import { DEFAULT_TABLE_SORTING_ORDER, SIZE_PER_PAGE_LIST } from '../../lib/constants';
import {
    userLookUpColumns,
    userLookupDataGridCss,
    defaultSortModel,
    DEFAULT_PAGE_SIZE
} from './UserLookupProps';

const UserLookupDataGrid: React.FunctionComponent = () => {
    const [pageNumber, setPageNumber] = React.useState(0);
    const [selectedPageSize, setSelectedPageSize] = React.useState(DEFAULT_PAGE_SIZE);
    const [tableSort, setTableSort] = React.useState<GridSortModel>(defaultSortModel);
    const { isGettingRoleByLogins } = useSelector((state: RootState) => state.userProfile);
    const { resolvedUsers } = useSelector((state: RootState) => state.userProfile.roleByLogins);

    useEffect(() => {
        setPageNumber(0);
    }, [resolvedUsers]);

    const tableSortChangeCallback = (newSort: GridSortModel): void => {
        setTableSort(newSort);
    };
    const tablePageSizeChange = (event: SetStateAction<number>): void => {
        setSelectedPageSize(event);
    };
    const tablePageNumberChange = (event: SetStateAction<number>): void => {
        setPageNumber(event);
    };

    return (
        <div>
            <div style={{ display: 'flex' }}>
                <h6 style={{ width: '85%', fontSize: '1.1rem' }}>User Permissions</h6>
            </div>
            <DataGridPro
                getRowId={(row) => row.login}
                rows={resolvedUsers}
                columns={userLookUpColumns}
                pagination
                pageSize={selectedPageSize}
                rowsPerPageOptions={SIZE_PER_PAGE_LIST}
                sortingOrder={DEFAULT_TABLE_SORTING_ORDER as GridSortDirection[]}
                onSortModelChange={tableSortChangeCallback}
                page={pageNumber}
                onPageChange={tablePageNumberChange}
                onPageSizeChange={tablePageSizeChange}
                sortModel={tableSort}
                loading={isGettingRoleByLogins}
                autoHeight
                components={{
                    Toolbar: () => <GridToolbar />,
                    Pagination: GridPagination
                }}
                disableColumnMenu={true}
                disableSelectionOnClick
                sx={userLookupDataGridCss}
                style={{ zIndex: 0 }}
            />
        </div>
    );
};
export default UserLookupDataGrid;
