import React, { useEffect } from 'react';
import '../../../css/Admin.css';
import AdminSidebar from '../../../components/Sidebar/AdminSidebar';
import Container from 'react-bootstrap/Container';
import { ROLES_PERMISSIONS_MESSAGES } from '../../../lib/constants';
import RolePermissionsDashboard from './RolePermissionsDashboard';
import PermissionsTeamsTable from '../../../components/Tables/PermissionsTeamsTable';
import { useAppDispatch } from '../../../context/store';
import { getPermissionsTeamsAsync } from '../../../slices/admin/rolePermissionsSlice';

const Admins = (): JSX.Element => {
    const dispatch = useAppDispatch();
    useEffect(() => {
        dispatch(getPermissionsTeamsAsync('admin'));
    }, []);
    return (
        <Container className='Admin' fluid>
            <AdminSidebar />
            <div className='DataAnonBody'>
                <div id='Default'>
                    <label style={{ fontSize: '1.2em', marginBottom: '5%' }}>
                        {ROLES_PERMISSIONS_MESSAGES.ADMINS_TAB_MSG}
                    </label>
                </div>
                <RolePermissionsDashboard tabName='admin' />
                <PermissionsTeamsTable tabName='admin' />
                <div>
                    <p>{ROLES_PERMISSIONS_MESSAGES.FOOTER_NOTE_ASSOCIATED}</p>
                </div>
            </div>
        </Container>
    );
};
export default Admins;
