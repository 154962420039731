import React from 'react';
import { GridColDef, GridSortDirection, GridSortModel } from '@mui/x-data-grid-pro';
import { ButtonProps } from '@mui/material/Button';
import ExportIcon from '@mui/icons-material/SaveAlt';
import { convertInvalidToNA, phonetoolLinkFormatter } from '../Tables/TableFormatters';
import {
    OnSiteRosterPropMap,
    OnSiteRosterTitlePropMap
} from '../../lib/dictionaries/IncidentDetailsOnSiteRosterDictionary';

export const onSiteRosterColumns: GridColDef[] = [
    {
        field: OnSiteRosterPropMap.id,
        headerName: OnSiteRosterTitlePropMap.id,
        hide: true,
        filterable: false,
        disableExport: true
    },
    {
        field: OnSiteRosterPropMap.empId,
        headerName: OnSiteRosterTitlePropMap.empId,
        flex: 0.8,
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: OnSiteRosterPropMap.firstName,
        headerName: OnSiteRosterTitlePropMap.firstName,
        flex: 1,
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: OnSiteRosterPropMap.lastName,
        headerName: OnSiteRosterTitlePropMap.lastName,
        flex: 1,
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: OnSiteRosterPropMap.login,
        headerName: OnSiteRosterTitlePropMap.login,
        flex: 0.8,
        resizable: true,
        renderCell: (params) => phonetoolLinkFormatter(params.value)
    },
    {
        field: OnSiteRosterPropMap.managerLogin,
        headerName: OnSiteRosterTitlePropMap.managerLogin,
        flex: 0.8,
        resizable: true,
        renderCell: (params) => phonetoolLinkFormatter(params.value)
    },
    {
        field: OnSiteRosterPropMap.lastSeen,
        headerName: OnSiteRosterTitlePropMap.lastSeen,
        flex: 1,
        type: 'dateTime',
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: OnSiteRosterPropMap.lastBadgePoint,
        headerName: OnSiteRosterTitlePropMap.lastBadgePoint,
        flex: 1,
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: OnSiteRosterPropMap.imtMembership,
        headerName: OnSiteRosterTitlePropMap.imtMembership,
        // cannot filter or sort because on back end imtMembership is not stored in DB, context added after query using facman api
        filterable: false,
        sortable: false,
        flex: 1,
        resizable: true,
        renderCell: (params) => {
            if (params.value?.length) {
                return [...params.value].sort().join(', ');
            } else {
                return convertInvalidToNA(params.value);
            }
        }
    },
    {
        field: OnSiteRosterPropMap.accountedFor,
        headerName: OnSiteRosterTitlePropMap.accountedFor,
        type: 'singleSelect',
        valueOptions: ['Yes', 'No'],
        renderCell: (params) =>
            params.value === true ? (
                <strong>
                    <span style={{ color: 'lime' }}>Yes</span>
                </strong>
            ) : (
                <strong>
                    <span style={{ color: 'red' }}>No</span>
                </strong>
            ),
        flex: 1,
        resizable: true
    },
    {
        field: OnSiteRosterPropMap.accountedBy,
        headerName: OnSiteRosterTitlePropMap.accountedBy,
        flex: 0.8,
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    },
    {
        field: OnSiteRosterPropMap.type,
        headerName: OnSiteRosterTitlePropMap.type,
        flex: 0.6,
        resizable: true,
        renderCell: (params) => convertInvalidToNA(params.value)
    }
];

export const DEFAULT_PAGE_SIZE = 10;

export const rosterDataGridCss = {
    '& .MuiDataGrid-columnHeaderTitleContainer': {
        ['& .MuiDataGrid-columnHeaderTitle']: {
            fontSize: '0.8rem',
            fontWeight: '600',
            overflow: 'visible',
            overflowWrap: 'break-word',
            whiteSpace: 'normal',
            lineHeight: '1.1'
        }
    },
    '& .MuiDataGrid-row': {
        fontSize: '0.8rem',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        ['& .MuiDataGrid-cell']: {
            overflowWrap: 'break-word',
            whiteSpace: 'normal'
        }
    },
    '& .MuiDataGrid-row:not': {
        fontSize: '0.8rem',
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        ['& .MuiDataGrid-cell']: {
            overflowWrap: 'break-word',
            whiteSpace: 'normal'
        }
    },
    '& .MuiDataGrid-row:not(.MuiDataGrid-row--dynamicHeight)>.MuiDataGrid-cell': {
        overflowWrap: 'break-word',
        whiteSpace: 'normal',
        lineHeight: '1.1'
    }
};

export const defaultSortModel: GridSortModel = [
    {
        field: OnSiteRosterPropMap.accountedFor,
        sort: 'desc' as GridSortDirection
    }
];

export const buttonBaseProps: ButtonProps = {
    color: 'primary',
    size: 'small',
    startIcon: <ExportIcon />
};
