import React from 'react';
import Container from 'react-bootstrap/Container';
import IncidentHistoryDataGrid from '../../components/DataGrids/IncidentsHistoryDataGrid';
import { NavLink } from 'react-bootstrap';
import { RP_WIKI_URL } from '../../lib/constants';
import '../../css/privacyMessage.css';

const IncidentHistory: React.FunctionComponent = () => {
    return (
        <Container fluid>
            <div style={{ display: 'flex', flexDirection: 'column' }} className='ml-n3 container-fluid'>
                <p className='incidentHistoryMessage'>
                    Note: For data privacy reasons, Personally Identifiable Information (PII) is anonymized
                    two days after an incident has closed. All incidents are retained for only 18 months. See
                    the
                    <NavLink className='incidentHistoryMessageLink' target='_blank' href={RP_WIKI_URL}>
                        Rally Point Wiki
                    </NavLink>
                    for details.
                </p>
                <IncidentHistoryDataGrid />
            </div>
        </Container>
    );
};

export default IncidentHistory;
