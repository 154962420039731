import React, { Dispatch, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import Select from 'react-select';
import '../../css/Modal.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import countries from 'i18n-iso-countries';
import localeEn from 'i18n-iso-countries/langs/en.json';
import Swal from 'sweetalert2';
import {
    CONFIRM_ADD_PERMISSIONS_TEAMS_MESSAGE,
    INVALID_ABACUSID_MESSAGE,
    PERMISSIONS_TEAMS_EXISTS_MESSAGES,
    VALIDATING_MESSAGE,
    VIEW_ROLE_MAP
} from '../../lib/constants';
import { addPermissionsTeamAsync, validateTeamIdAsync } from '../../slices/admin/rolePermissionsSlice';
type options = {
    value: string;
    label: string;
};
type Props = {
    role: string;
    isVisible: Dispatch<boolean>;
};

const TEAM_TYPE_SELECT_OPTIONS = [
    {
        label: 'Team',
        value: 'team'
    }
];
const PermissionsTeamAdd = ({ role, isVisible }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const [name, setName] = useState('');
    const [extId, setExtId] = useState('');
    const [selectedOptions, setSelectedOptions] = useState<options>(TEAM_TYPE_SELECT_OPTIONS[0]);
    const { validTeamId, isValidTeamIdLoading } = useSelector((state: RootState) => state.rolePermissions);
    const { userCardholderInformation } = useSelector((state: RootState) => state.userProfile);
    countries.registerLocale(localeEn);
    const handleSubmit = async (): Promise<void> => {
        const { value: confirmed } = await Swal.fire({
            title: CONFIRM_ADD_PERMISSIONS_TEAMS_MESSAGE,
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#2e7bff'
        });
        if (confirmed) {
            dispatch(addPermissionsTeamAsync(name, extId, role, userCardholderInformation.login));
            isVisible(false);
        }
    };

    const handleOptionChange = (values: options): void => {
        setSelectedOptions(values);
        setExtId('');
    };

    const handleAbacusIdChange = (value: string): void => {
        setExtId(value);
        if (value !== '' && value.trim() !== '') {
            dispatch(validateTeamIdAsync(value.trim(), selectedOptions ? selectedOptions.value : ''));
        }
    };

    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <Modal width='200px' centered show={isVisible} onHide={() => isVisible(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Add Group as {VIEW_ROLE_MAP[role as keyof typeof VIEW_ROLE_MAP]}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='formBody'>
                    <Form.Group controlId='inputCountry'>
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                            as='input'
                            value={name}
                            onChange={(event) => setName(event.target.value)}
                            placeholder='Enter name...'
                        ></Form.Control>
                        <Form.Control.Feedback type='invalid'>
                            {PERMISSIONS_TEAMS_EXISTS_MESSAGES}
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group controlId='teamTypes'>
                        <Form.Label>Team Type</Form.Label>
                        <Select
                            className='basic-single'
                            options={TEAM_TYPE_SELECT_OPTIONS}
                            placeholder={
                                <Form.Text className='Placeholder'>Select a Group Type...</Form.Text>
                            }
                            onChange={handleOptionChange}
                            value={selectedOptions}
                            styles={{
                                singleValue: (base: string[]) => ({
                                    ...base,
                                    fontSize: '10pt'
                                }),
                                option: (base: string[]) => ({
                                    ...base,
                                    fontSize: '10pt'
                                })
                            }}
                        />
                    </Form.Group>
                    <Form.Group controlId='teamId'>
                        <Form.Control
                            as='textarea'
                            value={extId}
                            onChange={(event) => handleAbacusIdChange(event.target.value)}
                            placeholder={'Enter Abacus Id...'}
                            isValid={extId.trim() !== '' && validTeamId}
                            isInvalid={extId.trim() !== '' && !validTeamId}
                            disabled={!selectedOptions}
                        />
                        {isValidTeamIdLoading && (
                            <Form.Control.Feedback type='invalid' style={{ fontSize: '9pt' }}>
                                {VALIDATING_MESSAGE}
                            </Form.Control.Feedback>
                        )}
                        {!isValidTeamIdLoading && (
                            <Form.Control.Feedback type='invalid' style={{ fontSize: '9pt' }}>
                                {INVALID_ABACUSID_MESSAGE + extId}
                            </Form.Control.Feedback>
                        )}
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='footer'>
                <Button disabled={!name || !extId || !validTeamId} size='sm' onClick={handleSubmit}>
                    Add Group
                </Button>
                <Button size='sm' variant='secondary' onClick={() => isVisible(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default PermissionsTeamAdd;
