import React, { Dispatch, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import '../../css/Modal.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useSelector } from 'react-redux';
import { useToggle } from 'react-use';
import { DateTime } from 'luxon';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import {
    addAnonymizationSettingAsync,
    deleteAnonymizationSettingAsync,
    updateAnonymizationSettingAsync
} from '../../slices/admin/dataAnonymizationSlice';
import countries from 'i18n-iso-countries';
import localeEn from 'i18n-iso-countries/langs/en.json';
import Swal from 'sweetalert2';
import {
    INVALID_DAYS_MESSAGE,
    CONFIRM_ADD_OVERRIDE_MESSAGE,
    CONFIRM_CHANGE_DEFAULT_OVERRIDE_MESSAGE,
    CONFIRM_EDIT_OVERRIDE_MESSAGE,
    CONFIRM_DELETE_OVERRIDE_MESSAGE,
    OVERRIDE_EXISTS_MESSAGE
} from '../../lib/constants';
import { toast } from 'react-toastify';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';

export enum OverrideTypes {
    AddOverride = 'Add Override',
    EditOverride = 'Edit Override',
    EditDefault = 'Change Default Override'
}

type Props = {
    overrideType: OverrideTypes;
    isVisible: Dispatch<boolean>;
    selectedCountry?: string;
    selectedDays?: number | null;
};

const Override = ({ overrideType, isVisible, selectedCountry, selectedDays }: Props): JSX.Element => {
    const dispatch = useAppDispatch();
    const [country, setCountry] = useState('');
    const [days, setDays] = useState(selectedDays);
    const [neverAnonymized, setNeverAnonymized] = useToggle(selectedDays === null);
    const { anonymizationSettings } = useSelector((state: RootState) => state.dataAnonymization);
    const { userCardholderInformation } = useSelector((state: RootState) => state.userProfile);
    countries.registerLocale(localeEn);

    const handleSubmit = async (): Promise<void> => {
        let inputDays;
        if (neverAnonymized) {
            inputDays = null;
        } else if (days == null || isNaN(days)) {
            inputDays = 0;
        } else {
            inputDays = days;
        }
        if (overrideType === OverrideTypes.AddOverride) {
            const { value: confirmed } = await Swal.fire({
                title: CONFIRM_ADD_OVERRIDE_MESSAGE,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#2e7bff'
            });
            if (confirmed) {
                dispatch(
                    addAnonymizationSettingAsync({
                        countryCode: country,
                        days: inputDays,
                        dateLastModified: DateTime.utc(),
                        modifiedBy: userCardholderInformation.login
                    })
                );
                isVisible(false);
            }
        } else if (overrideType === OverrideTypes.EditOverride) {
            const { value: confirmed } = await Swal.fire({
                title: CONFIRM_EDIT_OVERRIDE_MESSAGE,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#2e7bff'
            });
            if (confirmed && selectedCountry != null) {
                const alpha2Code = countries.getAlpha2Code(selectedCountry, 'en');
                if (alpha2Code) {
                    dispatch(
                        updateAnonymizationSettingAsync({
                            countryCode: alpha2Code,
                            days: inputDays,
                            dateLastModified: DateTime.utc(),
                            modifiedBy: userCardholderInformation.login
                        })
                    );
                } else {
                    toast.error(
                        <ToastErrorMessage
                            header={'Invalid country!'}
                            errorMessage={'Could not retrieve valid alpha-2 country code'}
                        />
                    );
                }
                isVisible(false);
            }
        } else {
            const { value: confirmed } = await Swal.fire({
                title: CONFIRM_CHANGE_DEFAULT_OVERRIDE_MESSAGE,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: '#2e7bff'
            });
            if (confirmed) {
                dispatch(
                    updateAnonymizationSettingAsync({
                        countryCode: 'DEFAULT',
                        days: inputDays,
                        dateLastModified: DateTime.utc(),
                        modifiedBy: userCardholderInformation.login
                    })
                );
                isVisible(false);
            }
        }
    };

    const handleDelete = async (): Promise<void> => {
        const { value: confirmed } = await Swal.fire({
            title: CONFIRM_DELETE_OVERRIDE_MESSAGE,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            confirmButtonColor: '#dc3645'
        });
        if (confirmed && selectedCountry != null) {
            const alpha2Code = countries.getAlpha2Code(selectedCountry, 'en');
            if (alpha2Code) {
                dispatch(deleteAnonymizationSettingAsync(alpha2Code));
            } else {
                toast.error(
                    <ToastErrorMessage
                        header={'Invalid country!'}
                        errorMessage={'Could not retrieve valid alpha-2 country code'}
                    />
                );
            }
            isVisible(false);
        }
    };

    return (
        <Modal width='200px' centered show={isVisible} onHide={() => isVisible(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{overrideType}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className='formBody'>
                    {overrideType === OverrideTypes.AddOverride && (
                        <Form.Group controlId='inputCountry'>
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                as='select'
                                value={country}
                                onChange={(event) => setCountry(event.target.value)}
                                isInvalid={anonymizationSettings.some(
                                    (setting) => setting.countryCode === country
                                )}
                            >
                                <option value='' disabled>
                                    Select a country...
                                </option>
                                {Object.entries(countries.getNames('en')).map((country) => (
                                    <option key={country[0]} value={country[0]}>
                                        {country[1]}
                                    </option>
                                ))}
                            </Form.Control>
                            <Form.Control.Feedback type='invalid'>
                                {OVERRIDE_EXISTS_MESSAGE}
                            </Form.Control.Feedback>
                        </Form.Group>
                    )}
                    {overrideType === OverrideTypes.EditOverride && (
                        <Form.Group controlId='inputCountry'>
                            <Form.Label>Country</Form.Label>
                            <Form.Control
                                as='select'
                                value={countries.getAlpha2Code(selectedCountry as string, 'en')}
                                disabled
                            >
                                <option value=''>{selectedCountry}</option>
                            </Form.Control>
                        </Form.Group>
                    )}
                    <Form.Group className='mb-3' controlId='inputDays'>
                        <Form.Label>Days</Form.Label>
                        <Form.Control
                            type='number'
                            placeholder='0-365'
                            onChange={(event) => setDays(parseInt(event.target.value))}
                            isInvalid={days != null && (days < 0 || days > 365)}
                            value={days == null ? '' : days}
                            disabled={neverAnonymized}
                        />
                        <Form.Control.Feedback type='invalid'>{INVALID_DAYS_MESSAGE}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className='mb-3' controlId='toggleAnonymize'>
                        <Form inline>
                            <Form.Check
                                type='switch'
                                onChange={setNeverAnonymized}
                                checked={neverAnonymized}
                            />
                            <Form.Label style={{ fontSize: 'small' }}>Never Anonymize?</Form.Label>
                        </Form>
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer className='footer'>
                <Button
                    disabled={
                        (!country && !selectedCountry) ||
                        (days != null && (days < 0 || days > 365)) ||
                        (overrideType === OverrideTypes.AddOverride &&
                            anonymizationSettings.some((setting) => setting.countryCode === country))
                    }
                    size='sm'
                    onClick={handleSubmit}
                >
                    Save
                </Button>
                <Button size='sm' variant='secondary' onClick={() => isVisible(false)}>
                    Cancel
                </Button>
            </Modal.Footer>
            {overrideType === OverrideTypes.EditOverride && (
                <Modal.Footer className='footer'>
                    <Button size='sm' variant='danger' onClick={handleDelete}>
                        Delete
                    </Button>
                </Modal.Footer>
            )}
        </Modal>
    );
};

export default Override;
