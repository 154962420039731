import React from 'react';
import { PermissionsContext, UserContext } from '../../lib/types';
import { compose } from 'redux';
import { connect, useSelector } from 'react-redux';
import PERMISSIONS_CONTEXT from 'GuardianPermissions/lib/config/permissionsContext';
import AUTH_CONTEXT from 'GuardianAuth/lib/auth/AuthContext';
import Container from 'react-bootstrap/Container';
import { Col, Row, Card } from 'react-bootstrap';
import { RootState } from '../../context/rootReducer';
import NoPermissionsView from '../NoPermissionsView';
import DimmedLoader from 'GuardianWidgetCommons/lib/components/DimmedLoader';
import { ROLES_PERMISSIONS_MESSAGES } from '../../lib/constants';
import RolePermissionsDashboard from '../admin/roles/RolePermissionsDashboard';
import PermissionsSidebar from '../../components/Sidebar/PermissionsSidebar';

type role = 'admin' | 'business' | 'eventOwner' | 'user';

const Profile = (): JSX.Element => {
    const { userCardholderInformation, isGettingUserCardholderInformation } = useSelector(
        (state: RootState) => state.userProfile
    );
    const { isGettingUserPermissionRoles } = useSelector((state: RootState) => state.userPermissions);
    const { webRole } = useSelector((state: RootState) => state.userPermissions?.userPermissionRoles);
    let profileMessage: string;
    let role: role;
    switch (webRole) {
        case 'Admin':
            profileMessage = ROLES_PERMISSIONS_MESSAGES.ADMINS_TAB_MSG;
            role = 'admin';
            break;
        case 'Business Team':
            profileMessage = ROLES_PERMISSIONS_MESSAGES.BUSINESS_TEAMS_TAB_MSG;
            role = 'business';
            break;
        case 'Event Owner':
            profileMessage = ROLES_PERMISSIONS_MESSAGES.EVENT_OWNERS_TAB_MSG;
            role = 'eventOwner';
            break;
        default:
            profileMessage = ROLES_PERMISSIONS_MESSAGES.USERS_TAB_MSG;
            role = 'user';
            break;
    }
    return userCardholderInformation ? (
        <DimmedLoader active={isGettingUserCardholderInformation || isGettingUserPermissionRoles}>
            <Container fluid>
                <Row className='my-2'>
                    <Col md={3} lg={2}>
                        <PermissionsSidebar />
                    </Col>
                    <Col>
                        <Card
                            style={{
                                minHeight: '100vh',
                                maxHeight: '100vh',
                                overflowY: 'auto',
                                overflowX: 'hidden'
                            }}
                        >
                            <Card.Body>
                                <Container className='Admin' fluid>
                                    <div className='DataAnonBody'>
                                        <div id='Default'>
                                            <label style={{ fontSize: '1.2em', marginBottom: '5%' }}>
                                                {profileMessage}
                                            </label>
                                        </div>
                                        <RolePermissionsDashboard tabName={role} />
                                    </div>
                                </Container>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </DimmedLoader>
    ) : (
        <NoPermissionsView />
    );
};

const mapPermissionStateToProps = (state: PermissionsContext): PermissionsContext => {
    const { permissionsContext } = state;
    return {
        permissionsContext
    };
};

const mapAuthStateToProps = (state: UserContext): UserContext => {
    const { userContext } = state;
    return {
        userContext
    };
};

export default compose(
    connect(mapPermissionStateToProps, null, null, { context: PERMISSIONS_CONTEXT }),
    connect(mapAuthStateToProps, null, null, { context: AUTH_CONTEXT })
)(Profile) as React.ComponentType;
