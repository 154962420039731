import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Image, ListGroup } from 'react-bootstrap';
import { RootState } from '../../context/rootReducer';
import Spinner from 'react-bootstrap/Spinner';
import useSearchLogins from '../../hooks/useSearchLogins';
import { useAppDispatch } from '../../context/store';
import { getRoleByLoginsAsync, resetRoleByLogins } from '../../slices/userProfile/userProfileSlice';
import { RiErrorWarningLine } from 'react-icons/ri';
import Select from 'react-select';
import { INVALID_LOGIN } from '../../lib/constants';

type Option = {
    value: string;
    label: string;
};

const UserLookup = (): JSX.Element => {
    const dispatch = useAppDispatch();
    const [selectedLogins, setSelectedLogins] = React.useState<Option[]>([]);
    const { login, setLogin, searchedLogins } = useSearchLogins();
    const { isSearchingLogins } = useSelector((state: RootState) => state.userProfile);
    const [duplicateInput, setDuplicateInput] = useState('');

    const handleUserEntryClick = (event: React.MouseEvent<Element, MouseEvent>, login: string): void => {
        event.preventDefault();
        if (!selectedLogins.some((e: Option) => e.value === login)) {
            setDuplicateInput('');
            setSelectedLogins([...selectedLogins, { value: login, label: login }]);
        } else {
            setDuplicateInput(login);
        }
        setLogin('');
    };

    const handleDeleteUserEntryClick = (values: Option[]): void => {
        setSelectedLogins(values ? values : []);
    };

    useEffect(() => {
        dispatch(resetRoleByLogins());
    }, []);

    useEffect(() => {
        if (selectedLogins && selectedLogins.length) {
            dispatch(getRoleByLoginsAsync(selectedLogins.map((e: Option) => e.value)));
        }
    }, [selectedLogins]);

    return (
        <Fragment>
            <Form className='pl-4 pt-2' onSubmit={(e) => e.preventDefault()}>
                <Form.Group
                    style={{
                        width: '20%',
                        display: 'flex',
                        flexDirection: 'column'
                    }}
                >
                    <Form.Label style={{ fontSize: '1.1rem' }}>Amazon Login</Form.Label>
                    <Select
                        onInputChange={(value: string, action: { action: string }) => {
                            if (action.action === 'input-change') {
                                setLogin(value);
                                setDuplicateInput('');
                            }
                        }}
                        onChange={handleDeleteUserEntryClick}
                        placeholder={`Enter Login...`}
                        inputValue={login}
                        value={selectedLogins}
                        isSearchable={selectedLogins.length < 10}
                        components={{
                            Option,
                            Menu: () => null,
                            MenuList: () => null,
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null
                        }}
                        hideSelectedOptions={false}
                        isMulti
                        isClearable
                    />
                    {duplicateInput && (
                        <div style={{ fontWeight: 'bold', color: 'red' }}>
                            <RiErrorWarningLine size='20' />
                            <Form.Label className='mt-2 ml-1'>{duplicateInput} is already added!</Form.Label>
                        </div>
                    )}

                    {selectedLogins.length >= 10 && (
                        <div style={{ fontWeight: 'bold', color: 'orange' }}>
                            <RiErrorWarningLine size='20' />
                            <Form.Label className='mt-2 ml-1'>Max user search limit reached.</Form.Label>
                        </div>
                    )}

                    {searchedLogins && searchedLogins.status === INVALID_LOGIN && (
                        <div style={{ fontWeight: 'bold', color: 'red' }}>
                            <RiErrorWarningLine size='20' />
                            <Form.Label className='mt-2 ml-1'>
                                Invalid login: {searchedLogins.login}
                            </Form.Label>
                        </div>
                    )}

                    {isSearchingLogins && (
                        <Spinner style={{ alignSelf: 'center' }} animation='border' className='mt-3' />
                    )}
                    {searchedLogins &&
                        !!searchedLogins.length &&
                        searchedLogins.map(
                            (user: { login: string; empId: string; firstName: string; lastName: string }) => (
                                <ListGroup.Item
                                    action
                                    onClick={(event) => handleUserEntryClick(event, user.login)}
                                    key={user.login}
                                >
                                    <Image
                                        className='pr-2'
                                        rounded
                                        style={{ width: '15%' }}
                                        src={`https://internal-cdn.amazon.com/badgephotos.amazon.com/?employeeid=${user.empId}`}
                                        fluid={true}
                                    />
                                    {user.firstName} {user.lastName} ({user.login})
                                </ListGroup.Item>
                            )
                        )}
                </Form.Group>
            </Form>
        </Fragment>
    );
};

export default UserLookup;
