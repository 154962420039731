import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { AvailableRoutes } from '../../AppRoutes';
import { FaUserSecret, FaUserTag } from 'react-icons/fa';
import { NavLink } from 'react-router-dom';
import '../../css/Admin.css';

const AdminSidebar = (): JSX.Element => {
    return (
        <Nav className='AdminSidebar flex-column'>
            <Nav.Item className='mt-3'>
                <Nav.Link
                    className='text-secondary'
                    as={NavLink}
                    to={AvailableRoutes.dataAnonymization}
                    activeStyle={{ fontSize: '12pt', fontWeight: 'bold', color: '#4f4f4f' }}
                >
                    <FaUserSecret className='sidebarIcon' />
                    Data Anonymization
                </Nav.Link>
                <Nav.Link
                    className='text-secondary ml-4'
                    as={NavLink}
                    to={AvailableRoutes.incidentAnonymization}
                    activeStyle={{ fontSize: '12pt', fontWeight: 'bold', color: '#4f4f4f' }}
                >
                    Incident History
                </Nav.Link>
                <Nav.Link
                    className='text-secondary ml-4'
                    as={NavLink}
                    to={AvailableRoutes.phoneAnonymization}
                    activeStyle={{ fontSize: '12pt', fontWeight: 'bold', color: '#4f4f4f' }}
                >
                    Phone Number
                </Nav.Link>
            </Nav.Item>
            <Nav.Item className='mt-3'>
                <Nav.Link
                    className='text-secondary'
                    as={NavLink}
                    to={AvailableRoutes.rolesAndPermissions}
                    activeStyle={{ fontSize: '12pt', fontWeight: 'bold', color: '#4f4f4f' }}
                >
                    <FaUserTag className='sidebarIcon' />
                    Roles & Permissions
                </Nav.Link>
                <Nav.Link
                    className='text-secondary ml-4'
                    as={NavLink}
                    to={AvailableRoutes.usersMenu}
                    activeStyle={{ fontSize: '12pt', fontWeight: 'bold', color: '#4f4f4f' }}
                >
                    Users
                </Nav.Link>
                <Nav.Link
                    className='text-secondary ml-4'
                    as={NavLink}
                    to={AvailableRoutes.eventOwnersMenu}
                    activeStyle={{ fontSize: '12pt', fontWeight: 'bold', color: '#4f4f4f' }}
                >
                    Event Owners
                </Nav.Link>
                <Nav.Link
                    className='text-secondary ml-4'
                    as={NavLink}
                    to={AvailableRoutes.businessTeamsMenu}
                    activeStyle={{ fontSize: '12pt', fontWeight: 'bold', color: '#4f4f4f' }}
                >
                    Business Teams
                </Nav.Link>
                <Nav.Link
                    className='text-secondary ml-4'
                    as={NavLink}
                    to={AvailableRoutes.adminsMenu}
                    activeStyle={{ fontSize: '12pt', fontWeight: 'bold', color: '#4f4f4f' }}
                >
                    Admins
                </Nav.Link>
            </Nav.Item>
        </Nav>
    );
};

export default AdminSidebar;
