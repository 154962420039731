import React from 'react';
import '../../css/Admin.css';
import AdminSidebar from '../../components/Sidebar/AdminSidebar';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import DataAnonymizationTable from '../../components/Tables/DataAnonymizationTable';
import Override, { OverrideTypes } from '../../components/Modals/Override';
import PhoneNumberOverride from '../../components/Modals/PhoneNumberOverride';
import { useToggle } from 'react-use';
import { RootState } from '../../context/rootReducer';
import { useAppDispatch } from '../../context/store';
import { AvailableRoutes } from '../../AppRoutes';
import { Redirect } from 'react-router-dom';
import {
    getAnonymizationSettingsAsync,
    getPhoneAnonymizationSettingsAsync
} from '../../slices/admin/dataAnonymizationSlice';
import { useSelector } from 'react-redux';
import {
    DEFAULT_OVERRIDE_DAYS,
    NEVER_ANONYMIZE_MESSAGE,
    DEFAULT_ANONYMIZE_TOGGLE
} from '../../lib/constants';

type Props = {
    isIncidentAnonymization?: boolean;
    isPhoneAnonymization?: boolean;
};
export const DataAnonymization = (): JSX.Element => {
    return <Redirect to={AvailableRoutes.incidentAnonymization} />;
};

export const Anonymization = ({ isIncidentAnonymization, isPhoneAnonymization }: Props): JSX.Element => {
    const [openEditDefault, setOpenEditDefault] = useToggle(false);
    const [isIncidentDefaultLodging, setIsIncidentDefaultLodging] = useToggle(true);
    const [isPhoneDefaultLodging, setIsPhoneDefaultLodging] = useToggle(true);
    const dispatch = useAppDispatch();
    const { anonymizationSettings, phoneAnonymizationSettings } = useSelector(
        (state: RootState) => state.dataAnonymization
    );
    const defaultAnonymizationSetting = anonymizationSettings.filter(
        (setting) => setting.countryCode === 'DEFAULT'
    );
    const defaultPhoneAnonymizationSetting = phoneAnonymizationSettings.filter(
        (setting) => setting.countryCode === 'DEFAULT'
    );
    let renderedDefaultAnonymizationSetting;
    if (isIncidentAnonymization) {
        if (defaultAnonymizationSetting.length !== 0) {
            if (defaultAnonymizationSetting[0].days == null) {
                renderedDefaultAnonymizationSetting = NEVER_ANONYMIZE_MESSAGE;
            } else {
                renderedDefaultAnonymizationSetting =
                    defaultAnonymizationSetting[0].days +
                    (defaultAnonymizationSetting[0].days !== 1 ? ' days' : ' day');
            }
        }
    } else {
        if (!defaultPhoneAnonymizationSetting.length) {
            renderedDefaultAnonymizationSetting = DEFAULT_ANONYMIZE_TOGGLE.ON;
        } else {
            renderedDefaultAnonymizationSetting = DEFAULT_ANONYMIZE_TOGGLE.OFF;
        }
    }
    if (isIncidentDefaultLodging && isIncidentAnonymization) {
        dispatch(getAnonymizationSettingsAsync());
        setIsIncidentDefaultLodging(false);
        setIsPhoneDefaultLodging(true);
    }
    if (isPhoneDefaultLodging && isPhoneAnonymization) {
        dispatch(getPhoneAnonymizationSettingsAsync());
        setIsIncidentDefaultLodging(true);
        setIsPhoneDefaultLodging(false);
    }

    return (
        <Container className='Admin' fluid>
            <AdminSidebar />
            <div className='DataAnonBody'>
                <div id='Default'>
                    <label style={{ fontSize: '1.7em', fontStyle: 'bold' }}>Default</label>
                    <br />
                    {renderedDefaultAnonymizationSetting}
                    <Button
                        size='sm'
                        onClick={() => {
                            setOpenEditDefault(true);
                        }}
                        className='btn btn-secondary'
                        style={{ marginLeft: '15px' }}
                    >
                        Edit
                    </Button>
                    {isIncidentAnonymization && openEditDefault && (
                        <Override
                            selectedCountry={'DEFAULT'}
                            overrideType={OverrideTypes.EditDefault}
                            isVisible={setOpenEditDefault}
                            selectedDays={
                                defaultAnonymizationSetting.length && defaultAnonymizationSetting[0].days
                                    ? defaultAnonymizationSetting[0].days
                                    : DEFAULT_OVERRIDE_DAYS
                            }
                        />
                    )}
                    {isPhoneAnonymization && openEditDefault && (
                        <PhoneNumberOverride
                            selectedCountry={'DEFAULT'}
                            overrideType={OverrideTypes.EditDefault}
                            isVisible={setOpenEditDefault}
                            defaultSettings={renderedDefaultAnonymizationSetting}
                        />
                    )}
                </div>
                <DataAnonymizationTable
                    isIncidentAnonymization={isIncidentAnonymization}
                    isPhoneAnonymization={isPhoneAnonymization}
                />
            </div>
        </Container>
    );
};
