import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { v4 as uuidv4 } from 'uuid';

interface ToggleSwitchButtonInputType {
    reset: () => void;
    cancel: () => void;
    label?: string;
}

const ToggleAutoReloadSwitch: ({ reset, cancel, label }: ToggleSwitchButtonInputType) => JSX.Element = ({
    reset,
    cancel,
    label
}) => {
    const [reloadPage, setReloadPage] = useState(true);

    const onToggleChange = (): void => {
        reloadPage ? cancel() : reset();
        setReloadPage(!reloadPage);
    };

    return (
        <Form inline>
            <Form.Check type='switch' onChange={onToggleChange} checked={reloadPage} id={uuidv4()} />
            <Form.Label>Auto Reload {label}</Form.Label>
        </Form>
    );
};
export default ToggleAutoReloadSwitch;
