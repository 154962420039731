import Styles from 'GuardianWidgetCommons/lib/components/Styles';
import Converter from 'GuardianWidgetCommons/lib/helper/converter';
import React from 'react';
import { HeaderFormatter } from 'react-bootstrap-table-next';
import { PHONETOOL_LINK_PREFIX, ADMIN_DIRECTORY_LINK_PREFIX } from '../../lib/constants';
import { STAGE } from '../../lib/globals';
import { anonymizeData } from '../../lib/helpers/helpers';

export const { convertInvalidToNA } = Converter;
export const { PointerCursor } = Styles;

export const headerFormatter: HeaderFormatter = (column, colIndex, { sortElement, filterElement }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', font: '10xp' }}>
            <div style={{ display: 'flex' }}>
                {column.text}
                {sortElement}
            </div>
            {filterElement}
        </div>
    );
};

export const phonetoolLinkFormatter = (login: string, prefix: string | null = null): JSX.Element => {
    if (login && login !== 'system') {
        let convertedLogin = convertInvalidToNA(login);
        // Anonymizing login alias' for lower enviroments
        convertedLogin = STAGE !== 'prod' ? anonymizeData(convertedLogin) : convertedLogin;

        return (
            <a target='_blank' rel='noopener noreferrer' href={`${PHONETOOL_LINK_PREFIX}${convertedLogin}`}>
                {prefix ? `${prefix}${convertedLogin}` : convertedLogin}
            </a>
        );
    } else {
        return prefix ? `${prefix}${convertInvalidToNA(login)}` : convertInvalidToNA(login);
    }
};

export const adminDirectoryLinkFormatter = (login: string, prefix: string | null = null): JSX.Element => {
    if (login && login !== 'system') {
        return (
            <a target='_blank' rel='noopener noreferrer' href={`${ADMIN_DIRECTORY_LINK_PREFIX}${login}`}>
                {prefix ? `${convertInvalidToNA(login)}${prefix}` : convertInvalidToNA(login)}
            </a>
        );
    } else {
        return prefix ? `${convertInvalidToNA(login)}${prefix}` : convertInvalidToNA(login);
    }
};
