import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useParams } from 'react-router';
import IncidentDetailsSideBar from '../../components/Cards/IncidentDetailsSideBar';
import IncidentAuditTrailDataGrid from '../../components/DataGrids/IncidentAuditTrailDataGrid';
import IncidentDetailsOnSiteRosterDataGrid from '../../components/DataGrids/IncidentDetailsOnSiteRosterDataGrid';
import { useAppDispatch } from '../../context/store';
import { searchIncidentAsync } from '../../slices/incidents/incidentDetailsSlice';

const IncidentDetails: React.FunctionComponent = () => {
    const dispatch = useAppDispatch();

    const { incidentId } = useParams<{ incidentId: string }>();
    const [tabKey, setTabKey] = useState('rosterTable');

    useEffect(() => {
        if (incidentId) {
            dispatch(searchIncidentAsync(incidentId));
        }
    }, [dispatch]);

    const handleSelect = (key: string | null): void => {
        if (key) {
            setTabKey(key);
        }
    };

    return (
        <Container fluid>
            <Row className='my-2'>
                <Col md={3} lg={3}>
                    <IncidentDetailsSideBar />
                </Col>
                <Col sm={12} md={9} lg={9} className='ml-md-n3 container-fluid'>
                    <Tabs activeKey={tabKey} onSelect={(key) => handleSelect(key)} className='mb-3'>
                        <Tab eventKey='rosterTable' title='On-Site Roster'>
                            {tabKey === 'rosterTable' ? <IncidentDetailsOnSiteRosterDataGrid /> : <></>}
                        </Tab>
                        <Tab eventKey='auditTrail' title='Audit Trail'>
                            {tabKey === 'auditTrail' ? <IncidentAuditTrailDataGrid /> : <></>}
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    );
};

export default IncidentDetails;
