import GuardianAuth from 'GuardianAuth/lib/auth/GuardianAuth';
import GuardianPermissions from 'GuardianPermissions/lib/components/GuardianPermissions';
import ToastErrorMessage from 'GuardianWidgetCommons/lib/components/ToastErrorMessage';
import AsyncRequest from 'GuardianWidgetCommons/lib/helper/asyncRequest';
import { DateTime } from 'luxon';
import React from 'react';
import { toast } from 'react-toastify';
import { DATE_FORMAT, ERROR_AUTO_CLOSE } from '../constants';
import { GET_CARDHOLDERS_API_ENDPOINT, STAGE } from '../globals';
import { AuditTrail, EventData, UserCardholderInformation, ValidatedLogins } from '../types';
import { IncidentsHistoryPropMap } from '../dictionaries/IncidentsHistoryDictionary';
import { GridFilterItem } from '@mui/x-data-grid-pro';
import { version } from '../../../package.json';

const { checkErrorResponse, doRequest } = AsyncRequest;

interface AccountedForDetails {
    totalOnSite: number;
    totalAccountedFor: number;
    percentageAccountedFor: string;
}

const PII_MASK = '******';

const padLeadingZeros = (num: number | undefined, size: number): string => {
    let s = '';
    if (num) {
        s = num + '';
    }
    while (s.length < size) {
        s = '0' + s;
    }
    return s;
};

/**
 * A helper function that parses data, and returns it with PII masking in the same format
 * @param data Data that needs to be anonymized, comes in as a string
 * @returns anonymized data in format of: data -> da******
 */
export const anonymizeData = (data: string): string => {
    return data.slice(0, 2) + PII_MASK;
};

export function differenceBetweenTimes(startTime: string, endTime?: string): string {
    let diff;
    if (endTime) {
        diff = DateTime.fromISO(endTime)
            .diff(DateTime.fromISO(startTime), ['hours', 'minutes', 'seconds', 'milliseconds'])
            .toObject();
    } else {
        diff = DateTime.fromISO(startTime)
            .diffNow(['hours', 'minutes', 'seconds', 'milliseconds'])
            .negate()
            .toObject();
    }
    const { hours, minutes, seconds } = diff;
    return `${padLeadingZeros(hours, 2)}:${padLeadingZeros(minutes, 2)}:${padLeadingZeros(seconds, 2)}`;
}

export function getAccountedForDetails(
    totalBadgeHolder: number,
    totalVisitors: number,
    badgeHoldersAccountedFor: number,
    visitorsAccountedFor: number
): AccountedForDetails {
    const totalOnSite = totalBadgeHolder + totalVisitors;
    const totalAccountedFor = badgeHoldersAccountedFor + visitorsAccountedFor;
    const percentageAccountedFor = totalOnSite
        ? Math.floor((totalAccountedFor / totalOnSite) * 10000) / 100
        : 0;

    return {
        totalOnSite,
        totalAccountedFor,
        percentageAccountedFor: percentageAccountedFor + '%'
    };
}

export function formatToLocalTime(time: DateTime): string {
    return time.toLocal().toFormat(DATE_FORMAT);
}

export async function useEnforcePermissions(
    initialized: { [x: string]: boolean },
    empId: string,
    object: string,
    action: string,
    domain: string
): Promise<boolean> {
    if (initialized[domain]) {
        const permissions = new GuardianPermissions();
        return await permissions.enforce(empId, object, action, domain);
    }

    return false;
}

// DateFilter returns an localized timestamp => convert that to UTC timestamp to get actual day user entered
// Convert actual date to timestamp based on end/start of day
export function formatLocalToDBDatetime(date: { date: Date; comparator: string }, startOfDay = true): number {
    let dbFormattedTimestamp;
    dbFormattedTimestamp = DateTime.fromISO(DateTime.fromJSDate(date.date, { zone: 'utc' }).toISODate());
    dbFormattedTimestamp = startOfDay
        ? dbFormattedTimestamp.startOf('day').toSeconds()
        : dbFormattedTimestamp.endOf('day').toSeconds();
    return Math.floor(dbFormattedTimestamp);
}

export function convertDateToDBDatetime(date: Date, startOfDay = true): number {
    let dbFormattedTimestamp;
    dbFormattedTimestamp = DateTime.fromISO(DateTime.fromJSDate(date, { zone: 'utc' }).toISODate());
    dbFormattedTimestamp = startOfDay
        ? dbFormattedTimestamp.startOf('day').toSeconds()
        : dbFormattedTimestamp.endOf('day').toSeconds();
    return Math.floor(dbFormattedTimestamp);
}

export const validateLoginsAsync = async (logins: string[]): Promise<ValidatedLogins> => {
    try {
        const request = {
            logins
        };
        let invalidLogins = [];
        let validLogins = [];
        const result = await doRequest(
            GET_CARDHOLDERS_API_ENDPOINT,
            request,
            GuardianAuth.createRequestAuthHeader()
        );
        const { results, failures } = result.data;
        if (failures && failures.logins && failures.logins.length) {
            invalidLogins = failures.logins;
        }
        if (results && results.length) {
            validLogins = results.map((cardholder: UserCardholderInformation) => cardholder.login);
        }
        return {
            validLogins,
            invalidLogins,
            validCardholders: results
        };
    } catch (error) {
        const errMessage = checkErrorResponse(error);
        toast.error(<ToastErrorMessage header={'Failed to validate logins.'} errorMessage={errMessage} />, {
            autoClose: ERROR_AUTO_CLOSE
        });
        throw new Error('Failed to validate logins.');
    }
};

export const parseEventData = (eventData: EventData[]): AuditTrail[] => {
    return eventData.map((event) => {
        const {
            data,
            issuer: user,
            message,
            id,
            timestamp,
            issuerType,
            eventType: { name: action }
        } = event;
        const time = formatToLocalTime(DateTime.fromISO(timestamp, { zone: 'utc' }));
        const parsedData = JSON.parse(data);
        let role = '';
        if (parsedData?.role) {
            if (parsedData.role.name) {
                role = parsedData.role.name;
            } else {
                role = parsedData.role;
            }
        } else if (issuerType?.name) {
            role = issuerType.name;
        }
        return {
            id,
            user,
            role,
            message,
            time,
            action
        };
    });
};

export const getAccountabilityColor = (percentage: number): string => {
    if (percentage >= 100) {
        return '#32D732';
    } else if (percentage >= 99) {
        return '#CECE00';
    } else if (percentage >= 66) {
        return '#FFA500';
    } else {
        return '#FF6666';
    }
};

export const addDateToFileName = (fileName: string): string => {
    const [name, extension] = fileName.split('.');
    const date = new Date();
    return `${name}-${date.getUTCFullYear()}_${date
        .getUTCMonth()
        .toString()
        .padStart(2, '0')}_${date
        .getUTCDate()
        .toString()
        .padStart(2, '0')}.${extension}`;
};

const dateCols = new Set([IncidentsHistoryPropMap.startTime, IncidentsHistoryPropMap.endTime]);

export const dateDiffSort = (oldFilters: GridFilterItem[], newFilters: GridFilterItem[]): boolean => {
    const currentDateFilters = new Set(
        oldFilters.filter((filter) => {
            if (dateCols.has(filter.columnField)) {
                return filter.columnField;
            }
        })
    );
    const newDateFilters = newFilters.filter((filter) => {
        if (dateCols.has(filter.columnField)) {
            return filter.columnField;
        }
    });
    for (const colName of newDateFilters) {
        if (!currentDateFilters.has(colName)) {
            return true;
        }
    }
    return false;
};

export const getStageEnv = (): string => {
    const versionNumber = version.split('-')[0];
    switch (STAGE) {
        case 'dev':
            return ` DEV ${versionNumber}`;
        case 'beta':
            return ` BETA ${versionNumber}`;
        case 'gamma':
            return ` GAMMA ${versionNumber}`;
        default:
            return '';
    }
};

export const getResponderBadgetimeAndEtString = (timestamp: number): string => {
    const date = new Date(timestamp);
    const options: Intl.DateTimeFormatOptions = {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true
    };

    const timeString = date.toLocaleString('en-US', options);
    const now = new Date().getTime();
    const elapsedMs = now - timestamp;
    const elapsedSeconds = Math.floor(elapsedMs / 1000);
    const elapsedMinutes = Math.floor(elapsedSeconds / 60);
    const elapsedHours = Math.floor(elapsedMinutes / 60);
    const elapsedMinutesRemainder = elapsedMinutes % 60;
    const elapsedTimeString = `(${elapsedHours}h ${elapsedMinutesRemainder}m)`;

    return `${timeString} ${elapsedTimeString}`;
};

export const getResponderExportFileTitle = (siteNames: string[]): string => {
    const dateTimeString = new Date()
        .toLocaleString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false
        })
        .replace(', ', '_');

    return `${siteNames.join('_')}_${dateTimeString}.csv`;
};
